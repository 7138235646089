import React, { useContext, useRef, useState } from 'react';
import '../../../scss/pages/signup.scss';
import strings from "../../../lang/strings";
import { IS_UI_MODE } from "../../../config/const";
import { storage } from "../../../mobx/store";
import { PageSettings } from "../../../config/page-settings";

const SignupProfile = ({ onNext }) => {

  const context = useContext(PageSettings);
  const [user, setUser] = useState(storage.getSignupUser());

  const fileRef = useRef();
  const [file, setFile] = useState(null);

  const onChange = (prop, value) => {
    if (typeof prop == "object") {
      setUser(prev => {
        return {
          ...prev,
          ...prop
        }
      });
      return;
    }
    setUser(prev => {
      return {
        ...prev,
        [prop]: value
      }
    });
  }

  const onConfirm = () => {
    if (IS_UI_MODE) {
      onNext?.();
      return;
    }

    if ((user?.profile ?? '') == '') {
      context.showAlert(strings.signup.must_select_photo);
      return;
    }

    // 프로필파일업로드
    let formData = new FormData();
    formData.append('file', file, file.name);

    context.post(
      'upload/image/member',
      formData,
      response => {
        storage.saveSignupUser({
          ...user,
          profile: response.image
        });
        onNext?.();
      }
    );
  }

  const onSelectPhoto = () => {
    fileRef.current?.click();
  }

  const photoCallback = e => {
    const files = e.target.files;
    if (files.length < 1) {
      return;
    }

    let file = files[0];
    let reader = new FileReader();

    reader.onloadend = () => {
      setFile(file);
      onChange('profile', reader.result);
    };
    reader.readAsDataURL(file);
  }

  return (
    <div className='profile'>
      <label className='guide'>{strings.signup.photo_guide.nl2br()}</label>
      {
        (user?.profile ?? '') != '' ?
          <img className='profile-img' src={user?.profile} alt='profile' />
          :
          <img className='profile-img' srcSet={'./assets/images/img_default_profile.png 3x'} alt='profile' />
      }
      <input type='file' ref={fileRef}
             style={{ display: 'none' }}
             accept={'image/png,image/jpg,image/jpeg'}
             onChange={photoCallback} />
      <button className='btn-select-photo' onClick={onSelectPhoto}>{strings.signup.select_photo}</button>
      <label className='tip'>{strings.signup.must_select_photo}</label>
      <button className='btn-complete' onClick={onConfirm}>{strings.signup.register_complete}</button>
    </div>
  );
}

export default SignupProfile;
