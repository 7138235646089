import React, { useContext, useState } from 'react';
import { PageSettings } from "../../config/page-settings";
import strings from "../../lang/strings";
import { rootStore } from '../../mobx/store';


const GiftSelectUserPopup = ({ active, onClose }) => {

  const context = useContext(PageSettings);

  const [keyword, setKeyword] = useState('');
  const [userList, setUserList] = useState([]);

  const getUserList = () => {
    if (keyword == '') {
      context.showAlert(strings.t_gift.enter_user_name);
      return;
    }

    // 키워드에 따른 유저리스트 블러오기
    context.get(
      'member/search',
      { keyword },
      response => {
        setUserList(response);
      }
    );
  }

  const onSelectUser = id => {
    context.showConfirm(
      strings.t_gift.gift_confirm,
      '',
      strings.signup.confirm,
      strings.my_page.cancel,
      response => {
        if (response == true) {
          context.post(
            'gift/send',
            {
              member: id
            },
            (response) => {
              sendGiftLink(response.id);
              onClose?.(true);
            }
          );
        }
      }
    );
  }

  const sendGiftLink = (id) => {
    if (window.ReactNativeWebView) {
      let obj = {
        func: 'sendGift',
        params: {
          giftSender: rootStore.getMe?.name, // '몬스터',
          giftLevel: rootStore.getMe?.level.name, // 'Premium',
          giftId: id
        }
      };

      let message = JSON.stringify(obj);
      window.ReactNativeWebView.postMessage(message);
    }
  }

  return (
    <div className={'gift-select-user' + (active ? ' active' : '')} onClick={onClose}>
      <div className='content' onClick={e => e.stopPropagation()}>
        <div className='divider' />
        <div className='search'>
          <input
            type='text'
            value={keyword}
            placeholder={strings.t_gift.enter_user_name}
            onChange={e => setKeyword(e.target.value)} />
          <button className='btn-search' onClick={getUserList}>
            <img srcSet={'../assets/images/icon_search_black.png 3x'} alt='search' />
          </button>
        </div>
        <div className='user-list'>
          {
            userList.map((it, idx) => (
              <div key={idx} className='user-item' onClick={() => onSelectUser(it.id)}>
                <div className='user-profile'>
                  <img src={it?.profile?.getImages() || ''} />
                </div>
                <label className='user-nickname'>{it?.name || ''}</label>
              </div>
            ))
          }
        </div>
      </div>
    </div>
  );

};

export default GiftSelectUserPopup;
