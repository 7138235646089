import React, { useContext, useEffect, useState } from 'react';
import { PageSettings } from "../../config/page-settings";


const TermsPopup = ({ active, type, onClose }) => {

  const context = useContext(PageSettings);
  
  const [htmlString, setHtmlString] = useState('');

  useEffect(() => {
    if (type > 0) {
      getTermsHtmlString(type);
    }
  }, [type])

  const getTermsHtmlString = (type) => {
    const fileLink = type == 1 ? 'http://1.234.63.213:3000/terms.html' : 'http://1.234.63.213:3000/privacy.html';
    fetch(fileLink)
      .then(result => {
        return result.text();
      })
      .then(page => {
        setHtmlString(page);
      });
  }

  return (
    <div className={'terms-view' + (active ? ' active' : '')} onClick={onClose}>
      <div className='content' onClick={e => e.stopPropagation()}>
        <span dangerouslySetInnerHTML={{ __html: htmlString }} />
      </div>
    </div>
  );

};

export default TermsPopup;
