import React, { useContext, useEffect, useState } from 'react';

import { PageSettings } from '../../../config/page-settings';
import Container from "../../../components/control/container";
import TopBar from "../../../components/control/top-bar";
import strings from "../../../lang/strings";
import { FORMAT } from "../../../config/const";
import dayjs from "dayjs";
import Pagination from "../../../components/control/pagination";
import { inject, observer } from "mobx-react";

const TicketUsageHistory = ({ rootStore }) => {

  const context = useContext(PageSettings);
  const me = rootStore.getMe;

  const [activePage, setActivePage] = useState(1);
  const [totalCount, setTotalCount] = useState(0);
  const [list, setList] = useState([]);

  const LIMIT_COUNT = 20;

  useEffect(() => {
    context.getMyInfo();
  }, []);

  useEffect(() => {
    getList();
  }, [activePage]);

  const getList = () => {

    // 티켓로그 블러오기
    context.get(
      'facility/ticket_log',
      {
        offset: (activePage - 1) * LIMIT_COUNT,
        limit: LIMIT_COUNT
      },
      response => {
        setList(response.list);
        setTotalCount(response.totalCount);
      }
    );
  }

  const onToggleReview = index => {
    setList(prev => prev.map((it, idx) => {
      return {
        ...it,
        selected: idx == index && !it.selected
      };
    }));
  }

  const onReviewChange = (e, index) => {
    setList(prev => prev.map((it, idx) => {
      return {
        ...it,
        content: idx == index ? e.target.value : it.content
      };
    }));
  }

  const onClickStar = (index, star) => {
    setList(prev => prev.map((it, idx) => {
      return {
        ...it,
        star: idx == index ? star : it.star
      };
    }));
  }

  const onSaveReview = index => {
    const item = list[index];

    if (item.star == null || item.star == 0) {
      context.showAlert('별점을 선택하세요.');
      return;
    }

    if (item.content == '') {
      context.showAlert(strings.side_menu.review_placeholder);
      return;
    }

    // 리뷰작성
    context.post(
      `facility/review/save/${item.id}`,
      {
        star: item.star,
        content: item.content
      },
      () => {
        context.showAlert(strings.side_menu.review_saved);
      }
    );
  }

  return (
    <Container className='ticket-usage-log'>
      <TopBar center={true} title={strings.side_menu.ticket_usage_history} />
      <div className='content'>
        <div className='top'>
          <p className='caption'>{strings.side_menu.remain_ticket}</p>
          <label className='info'>
            <span>{me.ticket.use_count}/{me.ticket.total_count}</span>
            {strings.formatString(strings.side_menu.remain_info, dayjs().format('M'))}
          </label>
        </div>
        <div className='table'>
          <div className='thead'>
            <label className='no'>{strings.side_menu.no}</label>
            <label className='used'>{strings.side_menu.facility_used}</label>
            <label className='date'>{strings.side_menu.date_use}</label>
            <label className='review'>{strings.side_menu.write_review}</label>
          </div>
          <div className='tbody'>
            {
              list.map((it, idx) => (
                <div key={idx} className='tr'>
                  <div className='review-info'>
                    <label className='no'>{it.id}</label>
                    <label className='used'>{it.facility.name}</label>
                    <label className='date'>{dayjs(it.create_date).format(FORMAT.DATE)}</label>
                    <div className='review'>
                      <button className='btn-write' onClick={() => onToggleReview(idx)}>
                        {strings.side_menu.write}
                      </button>
                    </div>
                  </div>
                  {
                    it.selected &&
                    <div className='review-content'>
                      <div className='stars'>
                        {
                          [...Array(5).keys()].map((star, star_idx) => (
                            <img key={star_idx} srcSet={`../assets/images/icon_star${it.star != null && it.star >= star_idx + 1 ? '_yellow' : '' }.png 2x`} alt='star' onClick={() => onClickStar(idx, star_idx + 1)} />
                          ))
                        }
                      </div>
                      <textarea
                        placeholder={strings.side_menu.review_placeholder}
                        value={it.content || ''}
                        onChange={e => onReviewChange(e, idx)} />
                      <button className='btn-write btn-save' onClick={() => onSaveReview(idx)}>
                        {strings.side_menu.edit_write}
                      </button>
                    </div>
                  }
                </div>
              ))
            }
          </div>
        </div>
        <Pagination
          activePage={activePage}
          pageCount={Math.ceil(totalCount / LIMIT_COUNT)}
          onPage={page => {
            if (page == activePage) {
              return;
            }
            setActivePage(page);
          }} />
      </div>
    </Container>
  );
}

export default inject('rootStore')(observer(TicketUsageHistory));
