import React, { useContext, useEffect, useState } from 'react';

import { PageSettings } from '../../../config/page-settings';
import Container from "../../../components/control/container";
import TopBar from "../../../components/control/top-bar";
import strings from "../../../lang/strings";
import { IS_UI_MODE } from "../../../config/const";
import ListView from "../../../components/control/list-view";

const Faq = () => {

  const context = useContext(PageSettings);

  const [type, setType] = useState('');
  const [loadingEnd, setLoadingEnd] = useState(false);
  const [list, setList] = useState([]);

  const LIMIT_COUNT = 20;

  useEffect(() => {
    getList(true);
  }, [type]);

  const getList = (refresh = false) => {
    if (!refresh && loadingEnd) {
      return;
    }

    if (IS_UI_MODE) {
      getDummyData();
      return;
    }

    context.get(
      'support/faq',
      {
        type,
        offset: refresh ? 0 : list.length,
        limit: LIMIT_COUNT
      },
      response => {
        const { list, loadMore } = response;
        setList(prev => refresh ? list : prev.concat(list));
        setLoadingEnd(!loadMore);
      }
    );
  }

  const getDummyData = () => {
    setList([...Array(10).keys()].map(it => {
      return {
        id: it,
        type: strings.side_menu.faq_type[it % 3 + 1].type,
        title: 'I want to sign up',
        content: 'blah blah',
        create_date: '2023-10-24 10:00:00',
        selected: false
      }
    }));
  }

  const toggleHeader = index => {
    setList(prev => prev.map((it, idx) => {
      return {
        ...it,
        selected: !it.selected && idx == index
      }
    }));
  }

  return (
    <Container className='faq'>
      <TopBar center={true} title={strings.side_menu.faq} />
      <div className='content'>
        <select className='category' value={type} onChange={e => setType(e.target.value)}>
          {
            strings.side_menu.faq_type.map((it, idx) => (
              <option key={idx} value={it.type}>{it.title}</option>
            ))
          }
        </select>
        <ListView className='list' onLoadMore={() => getList()}>
          {
            list.map((it, idx) => (
              <div key={idx} className='item'>
                <div className='header' onClick={() => toggleHeader(idx)}>
                  <label className='type'>{strings.side_menu.faq_type.find(t => t.type == it.type)?.title}</label>
                  <label className='title'>{it.title}</label>
                </div>
                {it.selected && <div className='body' dangerouslySetInnerHTML={{ __html: it.content }} />}
              </div>
            ))
          }
        </ListView>
      </div>
    </Container>
  );
}

export default Faq;
