import React, { useContext, useEffect, useState } from 'react';

import { PageSettings } from '../../../config/page-settings';
import Container from "../../../components/control/container";
import TopBar from "../../../components/control/top-bar";
import strings from "../../../lang/strings";
import { withRouter } from "react-router-dom";

const MembershipLevel = ({ history }) => {

  const context = useContext(PageSettings);
  const [levelList, setLevelList] = useState([]);

  useEffect(() => {
    context.getSettingInfo().then(() => {
      setLevelList(context.getSettingList('level'));
    });
  }, []);

  const onSelectLevel = (name, id) => {
    context.showConfirm('', `${name}을 선택하셨어요.\n만약 원하시는 제휴시설의 등급보다 아래합(등급)이라면 사용이 제한됩니다.\n \n구매 하시겠습니까?`, '예', '아니오', (res) => {
      if (res == true)
        history.push(`/membership/payment/${id}`);
    })
  }

  return (
    <Container className='membership-level'>
      <TopBar center={true} title={strings.my_page.membership_level} />
      <div className='content'>
        <div className='header'>
          <h4 className='title'>{strings.my_page.membership_level}</h4>
          <label className='desc'>{strings.my_page.membership_level_guide.nl2br()}</label>
        </div>
        <div className='list'>
          {
            levelList.map((it, idx) => (
              <div key={idx} className='item'>
                <div className='level-name'>{it.name}</div>
                <div className='info'>
                  <label className='price'>{`${it.cost.currencyFormat()}원`}</label>
                  <label className='capacity'>{strings.formatString(strings.my_page.facility_available, it.facility_count)}</label>
                  <label className='ticket'>{strings.formatString(strings.my_page.ticket_issued, it.ticket)}</label>
                </div>
                <button className='btn-select' onClick={() => {onSelectLevel(it.name, it.id)}}>
                  {strings.my_page.select}
                </button>
              </div>
            ))
          }
        </div>
      </div>
    </Container>
  );
}

export default withRouter(MembershipLevel);
