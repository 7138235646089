import React, { useContext, useState } from 'react';

import { PageSettings } from '../../../config/page-settings';
import Container from "../../../components/control/container";
import TopBar from "../../../components/control/top-bar";
import strings from "../../../lang/strings";

const PartnershipInquiry = () => {

  const context = useContext(PageSettings);

  const [facility, setFacility] = useState('');
  const [area, setArea] = useState(0);
  const [category, setCategory] = useState(0);
  const [name, setName] = useState('');
  const [phone, setPhone] = useState('');
  const [route, setRoute] = useState('');
  const [memo, setMemo] = useState('');

  const onApply = () => {
    if (
      facility == '' ||
      area == 0 ||
      category == 0 ||
      name == '' ||
      phone == '' ||
      route == '' ||
      memo == ''
    ) {
      context.showAlert(strings.side_menu.input_all);
      return;
    }

    context.post(
      'support/inquiry',
      {
        facility, area, category, name, phone, route, memo
      },
      () => {
        context.showAlert(strings.side_menu.inquiry_success);
      }
    );
  };

  return (
    <Container className='inquiry'>
      <TopBar center={true} title={strings.side_menu.partnership_inquiry} />
      <div className='content'>
        <p className='guide'>
          <p style={{ fontSize: 16, fontWeight: 'bold' }}><span className='samliphopang' style={{fontSize: 18, color: '#ff3a4a'}}>{`"합티"`}</span>{'는 제휴시설의 만족과 매출을 최우선으로 생각합니다.'}</p>
          <p>{'1. '}<span className='samliphopang' style={{fontSize: 16, color: '#ff3a4a'}}>{`"합티"`}</span>{'를 활용한 지역 커뮤니티에 노출!'}</p>
          <p>{'2. '}<span className='samliphopang' style={{fontSize: 16, color: '#ff3a4a'}}>{`"합티"`}</span>{'를 활용한 신규고객 유치!'}</p>
          <p>{'3. '}<span className='samliphopang' style={{fontSize: 16, color: '#ff3a4a'}}>{`"합티"`}</span>{'를 활용한 SNS홍보!'}</p>
        </p>
        <div className='box'>
          {/* <div className='top'>
            <div className='ring'>
              <div className='circle' />
            </div>
            <label>{strings.side_menu.inquiry_about}</label>
          </div> */}

          <label className='caption'>
            {'시설명'}
            <span className='required'>{'*필수'}</span>
          </label>
          <input value={facility} onChange={e => setFacility(e.target.value)}
                 placeholder={'시설명을 입력해주세요'} />

          <label className='caption'>
            {strings.side_menu.select_region}
            <span className='required'>{'*필수'}</span>
          </label>
          <select value={area} onChange={e => setArea(e.target.value)}>
            <option value={0}>{strings.my_page.select}</option>
            {
              context.getSettingList('area').map((it, idx) => (
                <option key={idx} value={it.id}>{it.name}</option>
              ))
            }
          </select>

          <label className='caption'>{strings.side_menu.facility_type}</label>
          <label className='desc'>{'시설종목에 해당종목이 없을시, 메모란에 적어주세요.'}</label>
          <select value={category} onChange={e => setCategory(e.target.value)}>
            <option value={0}>{strings.my_page.select}</option>
            {
              context.getSettingList('category').map((it, idx) => (
                <option key={idx} value={it.id}>{it.name}</option>
              ))
            }
          </select>

          <label className='caption'>{strings.side_menu.contact_person}</label>
          <input value={name} onChange={e => setName(e.target.value)}
                 placeholder={strings.side_menu.contact_person_placeholder} />

          <label className='caption'>
            {strings.side_menu.enter_phone_number}
            <span className='required'>{'*필수'}</span>
          </label>
          <label className='desc'>{'적어주신 연락처로, 담당자가 빠르게 연락드리겠습니다.'}</label>
          <input type='tel' value={phone} onChange={e => setPhone(e.target.value)}
                 placeholder={strings.side_menu.enter_phone_number_placeholder} />

          <label className='caption'>{'문의경로'}</label>
          <select value={route} onChange={e => setRoute(e.target.value)}>
            <option value={''}>{strings.my_page.select}</option>
            {
              ['포털사이트', 'SNS', '블로그', '리플렛', '지인소개', '회사게시판', '기타'].map((it, idx) => (
                <option key={idx} value={it}>{it}</option>
              ))
            }
          </select>

          <label className='caption'>{strings.side_menu.memo}</label>
          <textarea value={memo} onChange={e => setMemo(e.target.value)} />
        </div>

        <button className='btn-apply' onClick={onApply}>{strings.side_menu.apply}</button>
      </div>
    </Container>
  );
}

export default PartnershipInquiry;
