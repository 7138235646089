import React, { useCallback, useContext, useRef } from 'react';
import QuickPinchZoom, { make3dTransformValue } from "react-quick-pinch-zoom";
import { PageSettings } from "../../config/page-settings";


const ImagePopup = ({ active, url, onClose }) => {

  const context = useContext(PageSettings);

  const imgRef = useRef();

  const onUpdate = useCallback(({ x, y, scale }) => {
    const { current: img } = imgRef;

    if (img) {
      const value = make3dTransformValue({ x, y, scale });

      img.style.setProperty("transform", value);
    }
  }, []);

  return (
    <div className={'image-popup' + (active ? ' active' : '')} onClick={onClose}>
      <div className='btn-close' onClick={onClose}>
        <img srcSet={'../assets/images/icon_close_white.png'} alt='close' />
      </div>
      <div className='content'>
        <QuickPinchZoom onUpdate={onUpdate}>
          <img ref={imgRef} src={url} />
        </QuickPinchZoom>
      </div>
    </div>
  );

};

export default ImagePopup;
