import React, { useContext, useEffect, useRef, useState } from 'react';

import { PageSettings } from '../../../config/page-settings';
import Container from "../../../components/control/container";
import TopBar from "../../../components/control/top-bar";
import strings from "../../../lang/strings";
import '../../../scss/pages/mypage.scss'
import { inject, observer } from "mobx-react";
import { withRouter } from "react-router-dom";
import { LOGIN_TYPE } from "../../../config/const";
import Sheet from "react-modal-sheet";
import DaumPostcodeEmbed from 'react-daum-postcode';

const AccountInfo = ({ rootStore, history }) => {

  const context = useContext(PageSettings);
  
  const { kakao } = window;
  const geocoder = new kakao.maps.services.Geocoder();

  const [user, setUser] = useState();
  const [oldPwd, setOldPwd] = useState('');
  const [oldPwdConfirm, setOldPwdConfirm] = useState('');
  const [newPwd, setNewPwd] = useState('');
  const [newPwdConfirm, setNewPwdConfirm] = useState('');

  const addressType = useRef('');
  const [isSearchAddressOpen, setSearchAddressOpen] = useState(false);

  const imageRef = useRef(null);
  const [file, setFile] = useState(null);

  useEffect(() => {
    setUser(rootStore.getMe);
  }, [rootStore.getMe]);

  const onChange = (prop, val) => {
    setUser(prev => {
      return {
        ...prev,
        [prop]: val
      }
    });
  }

  const onPhoneVerify = () => {
    // TODO: 전화번호인증처리
  }

  const onSearchAddress = type => {
    addressType.current = type;
    setSearchAddressOpen(true);
  }

  const onConfirm = () => {
    if (user?.login_type == LOGIN_TYPE.EMAIL) {
      if (user?.email == '') {
        context.showAlert(strings.signup.email_placeholder);
        return;
      }
      if (newPwd != '') {
        if (
          newPwd.search(/[0-9]/g) < 0 ||
          newPwd.search(/[a-z]/ig) < 0 ||
          newPwd.search(/[A-Z]/ig) < 0
        ) {
          context.showAlert(strings.signup.password_placeholder);
          return;
        }
        if (newPwd != newPwdConfirm) {
          context.showAlert(strings.signup.confirm_placeholder);
          return;
        }
      }

      // 이메일 중복체크
      context.get(
        'auth/check',
        {
          email: user?.email ?? '',
          phone: user?.phone ?? '',
          name: user?.name ?? ''
        },
        response => {
          const { email, phone, name } = response;
          if (!email && user?.login_type == LOGIN_TYPE.EMAIL) {
            context.showAlert(strings.signup.duplicate_email);
            return;
          }
          if (!phone || !name) {
            context.showAlert(strings.signup.duplicate_phone);
            return;
          }

          fileUpload();
        }
      );
    } else {
      fileUpload();
    }
  }

  const fileUpload = () => {
    if (file != null) {
      // 프로필파일업로드
      let formData = new FormData();
      formData.append('file', file, file.name);

      context.post(
        'upload/image/member',
        formData,
        response => {
          saveInfo(response.image);
        }
      );
    } else {
      saveInfo();
    }
  }

  const saveInfo = (profile = '') => {
    context.post(
      'member/update',
      {
        ...user,
        profile: profile || user?.profile,
        new_pwd: newPwd
      },
      () => {
        context.getMyInfo();
        context.showAlert(strings.my_page.account_modify);
      }
    );
  }

  const photoCallback = e => {
    const files = e.target.files;
    if (files.length < 1) {
      return;
    }

    let file = files[0];
    let reader = new FileReader();

    reader.onloadend = () => {
      setFile(file);
      onChange('profile', reader.result);
    };
    reader.readAsDataURL(file);
  }

  const handleSearchComplete = (data) => {
    let fullAddress = data.address;
    let extraAddress = '';

    if (data.addressType === 'R') {
      if (data.bname !== '') {
        extraAddress += data.bname;
      }
      if (data.buildingName !== '') {
        extraAddress += extraAddress !== '' ? `, ${data.buildingName}` : data.buildingName;
      }
      fullAddress += extraAddress !== '' ? ` (${extraAddress})` : '';
    }

    getCoordFromAddress(data.jibunAddress);
    // onChange(`${addressType.current}_address`, data.jibunAddress);
    console.log(fullAddress); // e.g. '서울 성동구 왕십리로2길 20 (성수동1가)'
    setSearchAddressOpen(false);
  };

  const getCoordFromAddress = (address) => {
    geocoder.addressSearch(address, (result, status) => {
      if (status == kakao.maps.services.Status.OK) {
        const lat = result[0].y;
        const lng = result[0].x;

        onChange(`${addressType.current}_address`, address);
        onChange(`${addressType.current}_lat`, lat);
        onChange(`${addressType.current}_lng`, lng);
      }
    });
  }

  return (
    <Container className='account-info'>
      <TopBar center={true} title={strings.my_page.account_info} />
      <div className='content'>
        <div className='top'>
          <div className='profile' onClick={() => imageRef.current.click()}>
            {
              user?.profile != '' ?
                <img src={user?.profile?.getFullUrl()} alt='profile' />
                :
                <img srcSet={'./assets/images/img_default_profile.png 3x'} alt='profile' />
            }
            <label>{strings.my_page.profile_settings}</label>
            <input type='file' ref={imageRef} style={{ display: 'none' }} accept={'image/png,image/jpg,image/jpeg'}
                   onChange={photoCallback} />
          </div>
          <button className='btn-change-account'>{strings.my_page.account_settings}</button>
        </div>
        {
          user?.level == null &&
          <button className='btn-login-require' onClick={() => history.push('/membership/level')}>
            {strings.my_page.membership_register_required}
          </button>
        }

        {/*ID*/}
        {
          user?.login_type == LOGIN_TYPE.EMAIL &&
          <>
            <label className='caption' style={{ marginTop: 45 }}>{strings.signup.id}</label>
            <div className='input-wrapper'>
              <input type='email' value={user?.email || ''} placeholder={strings.signup.email_placeholder}
                     onChange={e => onChange('email', e.target.value)} />
            </div>
          </>
        }
        {/*Current Password Confirm*/}
        {/*<label className='caption'>{strings.signup.current_password}</label>
        <div className='input-wrapper'>
          <input type='password' value={oldPwd} placeholder={strings.signup.password_placeholder}
                 onChange={e => setOldPwd(e.target.value)} />
        </div>*/}
        {/*Password*/}
        {/*<label className='caption'>{strings.signup.password}</label>
        <div className='input-wrapper'>
          <input type='password' value={oldPwdConfirm} placeholder={strings.signup.confirm_placeholder}
                 onChange={e => setOldPwdConfirm(e.target.value)} />
        </div>*/}
        {/*Phone identity Button*/}
        {/* <button className='btn-phone-verify' onClick={onPhoneVerify}>{strings.signup.mobile_phone_verify}</button> */}
        {/*Home Address*/}
        <label className='caption'>{strings.signup.home_address}</label>
        <div className='address-wrapper'>
          <div className='address'>{(user?.home_address) || ''}</div>
          <button className='btn-search' onClick={() => onSearchAddress('home')}>
            <img srcSet={'./assets/images/icon_search.png 3x'} />
          </button>
        </div>
        {/*Office Address*/}
        <label className='caption'>{strings.signup.office_address}</label>
        <div className='address-wrapper'>
          <div className='address'>{(user?.office_address) || ''}</div>
          <button className='btn-search' onClick={() => onSearchAddress('office')}>
            <img srcSet={'./assets/images/icon_search.png 3x'} />
          </button>
        </div>
        <label className='address-guide'>{strings.signup.enter_address_correctly}</label>
        {
          user?.login_type == LOGIN_TYPE.EMAIL &&
          <>
            {/*Password to change*/}
            <label className='caption'>{strings.my_page.password_change}</label>
            <div className='input-wrapper'>
              <input type='password' value={newPwd} placeholder={strings.my_page.password_change_placeholder}
                     onChange={e => setNewPwd(e.target.value)} />
            </div>
            {/*Confirm Password to change*/}
            <label className='caption'>{strings.my_page.confirm_password_change}</label>
            <div className='input-wrapper'>
              <input type='password' value={newPwdConfirm}
                     placeholder={strings.my_page.confirm_password_change_placeholder}
                     onChange={e => setNewPwdConfirm(e.target.value)} />
            </div>
          </>
        }
        {/*Agree to receive*/}
        <label className='caption' style={{ marginTop: 6 }}>{strings.my_page.agree_receive}</label>
        <div className='switch-group'>
          <div className='switch-item'>
            <label className='name'>{strings.signup.email}</label>
            <label className='date'>({user?.market_email_date?.substr(0, 10) || '-'})</label>
            <button className='btn-switch' onClick={() => onChange('market_email', 1 - (user?.market_email ?? 0))}>
              <img srcSet={(user?.market_email ?? 0) == 1 ?
                './assets/images/btn_switch_on.png 3x' :
                './assets/images/btn_switch_off.png 3x'} />
            </button>
          </div>
          <div className='switch-item'>
            <label className='name'>{strings.signup.sms}</label>
            <label className='date'>({user?.market_sms_date?.substr(0, 10) || '-'})</label>
            <button className='btn-switch' onClick={() => onChange('market_sms', 1 - (user?.market_sms ?? 0))}>
              <img srcSet={(user?.market_sms ?? 0) == 1 ?
                './assets/images/btn_switch_on.png 3x' :
                './assets/images/btn_switch_off.png 3x'} />
            </button>
          </div>
          <div className='switch-item'>
            <label className='name'>{strings.signup.push}</label>
            <label className='date'>({user?.market_push_date?.substr(0, 10) || '-'})</label>
            <button className='btn-switch' onClick={() => onChange('market_push', 1 - (user?.market_push ?? 0))}>
              <img srcSet={(user?.market_push ?? 0) == 1 ?
                './assets/images/btn_switch_on.png 3x' :
                './assets/images/btn_switch_off.png 3x'} />
            </button>
          </div>
        </div>

        <button className='btn-confirm' onClick={onConfirm}>{strings.signup.confirm}</button>
      </div>
      <Sheet
          isOpen={isSearchAddressOpen}
          onClose={() => setSearchAddressOpen(false)}
          disableDrag>
          <Sheet.Container>
            <Sheet.Header>
              <div className='modal-sheet-header'>
                <div className='left' onClick={() => setSearchAddressOpen(false)}>
                  <img src='assets/images/icon_close.png' />
                </div>
                <label className='center'>주소 검색</label>
                <div className='right'>
                </div>
              </div>
            </Sheet.Header>
            <Sheet.Content>
              <DaumPostcodeEmbed onComplete={handleSearchComplete} style={{height: '100%'}} />
            </Sheet.Content>
          </Sheet.Container>
          <Sheet.Backdrop onClick={() => setSearchAddressOpen(false)} />
        </Sheet>
    </Container>
  );
}

export default withRouter(inject('rootStore')(observer(AccountInfo)));
