export default {
  intro: {
    start_with_google: 'Get started with Google',
    start_with_facebook: 'Get started with Facebook',
    start_with_email: 'Get started with Email',
    login: 'Login',
    explore_without: 'Explore without registering'
  },
  login: {
    email_placeholder: 'Please enter your email address',
    password_placeholder: 'Please enter a password',
    find_id: 'Find ID/',
    find_pwd: 'Pwd',
    sign_up: 'Sign Up',
    change_your_phone: 'Have you changed your phone?',
    phone_auth: 'Phone Auth',
    confirm: 'Confirm'
  },
  find: {
    find_id: 'Find ID',
    find_pwd: 'Find Password',
    phone: 'Phone',
    find: 'Find',
    success: 'success'
  },
  signup: {
    member_info: 'Member Info',
    id: 'ID',
    email_placeholder: 'Enter email address',
    duplicate_email: 'Duplicate email',
    duplicate_phone: 'Duplicate phone',
    password: 'Password',
    current_password: 'Current Password Confirm',
    password_placeholder: 'Enter a mix of num, upper and lowercase letters.',
    confirm_placeholder: 'Enter it again',
    mobile_phone_verify: 'Mobile phone identity verification',
    home_address: 'Home Address',
    select_home_address: 'Select home address',
    office_address: 'Office Address',
    select_office_address: 'Select office Address',
    enter_address_correctly: 'Enter the address correctly',
    consent_marketing: 'Consent to receive marketing',
    email: 'Email',
    sms: 'SMS',
    push: 'Push',
    agree_terms: 'Agree to terms and conditions',
    terms_of_use: 'Terms of Use',
    privacy_policy: 'Privacy Policy',
    invitation_code: 'Invitation Code',
    invitation_code_placeholder: 'Please enter the invitation Code',
    confirm: 'Confirm',
    prev: 'Prev',
    next: 'Next',
    select_gender_age: 'Please select your\ngender and age group',
    recommend_gender_age: 'Recommended for the gender and age group.',
    age_woman: '{0}s Woman',
    age_man: '{0}s Man',
    select_category: 'Please select the category\nyou are interested in',
    multiple_possible: 'Multiple selection possible',
    select_area: 'Please select\nan area of interest',
    photo_guide: 'It is required when visiting affiliated facilities\n(You cannot use it if you are not the user.)',
    select_photo: 'Select photo',
    must_select_photo: 'You must select a photo that identifies you as a member.',
    register_complete: 'Registration complete',
  },
  gnb: {
    home: 'Home',
    near_by: 'Nearby',
    t_gift: 'TGift',
    my_page: 'MyPage'
  },
  home: {
    register_membership: 'Register for membership',
    check_facilities_around: 'Check facilities around me',
    guide: 'Choose a membership program.\nAll membership members receive affiliate\ndiscount services and usage benefits every month.',
    city: 'City',
    category: 'Category',
    more: 'More',
    notice_event: 'Notice/Event',
    make_reservation: 'Make Reservation',
    used_items: 'Used items',
    user_guide: 'User Guide',
    facility_info: 'Facility Info',
    review: 'Review',
    reservation: 'Reservation',
    day_list: ['Sun', 'Mon', 'Tue', 'Wed', 'Thr', 'Fri', 'Sat'],
    select_date: 'Please select a date',
    selected_date: 'Selected date',
    hours_use: 'Hours of use',
    start_time: 'Start Time',
    end_time: 'End Time',
    reservation_confirm: 'Reservation Confirm',
    reservation_confirmed: 'Reservation has been confirmed',
    select_reserve_date: 'Select reservation date',
    select_reserve_time: 'Select reservation time',
    select_reserve_time_correct: 'Select correct reservation time',
    administrator: 'Administrator',
    select_service_you_with: 'Please select the service you with to use\namong the services provided by the facility.',
    add_required: 'Add {0}$ Required',
    no_required: 'No payment required',
    login_first: 'Login First'
  },
  t_gift: {
    giftable_ticket: 'Giftable ticket',
    gift_count: '{0}T',
    create_link: 'Create gift link',
    gift_policy: 'Gift policy',
    gift_usage_history: 'Gift usage history',
    id: 'ID',
    facility_used: 'Facilities used',
    date_of_use: 'Date of use',
    enter_user_name: 'Enter user name',
    gift_confirm: 'Are you sure about it?',
    gift_success: 'Successfully gifted'
  },
  my_page: {
    change_account: 'Change Account Info',
    profile_settings: 'Profile Settings',
    membership_register_required: 'Membership registration is required',
    withdraw: 'Withdraw',
    account_info: 'Account Info',
    account_settings: 'Account Settings',
    password_change: 'Password to change',
    password_change_placeholder: 'Please enter a password to change',
    confirm_password_change: 'Confirm Password to change',
    confirm_password_change_placeholder: 'Please enter your password to change again.',
    agree_receive: 'Agree to receive',
    current_date: '(Current Date)',
    membership_level: 'Membership Level',
    membership_level_guide:
      '15 tickets issued/Can be used once per day' +
      'If you do not select membership, you will be registered as an associate member.',
    facility_available: '{0} facilities available',
    ticket_issued: '{0} tickets issued/Can be used once per day',
    select: 'Select',
    membership_payment: 'Membership Payment',
    start_date_use: 'The start date of use is {0}',
    credit_card_payment: 'Credit card payment is made automatically on the payment date every month.',
    use_it_early: 'If you would like to use it early, please select the start date.',
    select_start_date: 'Select start date of use',
    prepaid_early:
      'Prepaid early usage will expire at the end of the month.\n' +
      'You cannot cancel your subscription if you use the prepayment early.',
    membership_fee: 'Membership Fee',
    only_charge_once: 'You will only be charged once when you sign up for membership.',
    have_promo_code: 'Do you have a promo code?',
    discount: 'Discount',
    enter_code: 'Please enter the code',
    confirm_coupon: 'Confirm Coupon',
    register_corporate: 'Would you like to register as a corporate member?',
    company_placeholder: 'Enter company name',
    upload: 'Upload',
    agree_regular_payment: 'Agree to regular credit card payment',
    every_month: '25th of every month',
    first_payment: 'First Payment',
    next_payment: 'Next Payment',
    agree_membership_fees: 'I agree to withdrawal of membership fees',
    i_agree: 'I agree.',
    start_current_month: 'Start the current month',
    make_payment: 'Make payment',
    go_to_login: 'Go to Login',
    cancel: 'Cancel',
    withdrawal_application: 'Withdrawal application',
    today: '{0} (Today)',
    withdraw_membership: 'Withdraw membership',
    enter_content: 'Enter Content',
    like_withdraw: 'Would you like to withdraw?',
    will_withdraw: 'I will withdraw',
    account_modify: 'Successfully modified'
  },
  side_menu: {
    log_out: 'Log Out',
    log_in: 'Log In',
    log_out_confirm: 'Are you sure to logout?',
    ticket_usage: 'Ticket usage/purchase history',
    faq: 'FAQ',
    faq_type: [
      { type: '', title: 'All' },
      { type: 'S', title: 'Signup' },
      { type: 'P', title: 'Partnership' },
      { type: 'M', title: 'Membership' },
    ],
    notice_event: 'Notice/Event',
    partnership_inquiry: 'Partnership inquiry',
    all: 'All',
    title: 'Title',
    content: 'Content',
    search: 'Search',
    total: 'Total {0}',
    no: 'No',
    notice: 'Notice',
    partnership_guide: 'If you would like to become a partner, please contact us anytime',
    inquiry_about: 'Inquiry about affiliated facilities',
    select_region: 'Select region',
    facility_type: 'Facility type',
    contact_person: 'Contact person name',
    contact_person_placeholder: 'Please enter the person in charge\'s name and title',
    enter_phone_number: 'Enter phone number',
    enter_phone_number_placeholder: 'Please enter contact information where we can contact you quickly.',
    memo: 'Memo',
    apply: 'Apply',
    input_all: 'Input all information',
    inquiry_success: 'Successfully applied',
    ticket_usage_history: 'Ticket Usage History',
    remain_ticket: 'Remaining Ticket',
    remain_info: '{0}M',
    facility_used: 'Facilities used',
    date_use: 'Date of use',
    write_review: 'Write a review',
    write: 'Write',
    review_placeholder: 'Please write a review',
    edit_write: 'Edit/Write',
    review_saved: 'Review saved'
  },
  nfc: {
    select_service: 'Please select service you with to use\namong the services provided by the facility.',
    classes: '{0} classes',
    add_required: 'Add {0}$ required',
    select_option: 'Select Option',
    payment_method: 'Payment method',
    additional_payment: 'Additional payment: {0}$',
    visit_confirmed: 'Your visit has been confirmed'
  }
}
