import React, { useContext, useState } from 'react';
import { withRouter } from 'react-router-dom';

import { PageSettings } from '../../config/page-settings';
import Container from "../../components/control/container";
import strings from "../../lang/strings";

import '../../scss/pages/find.scss';
import TopBar from "../../components/control/top-bar";


const Find = ({ history, type }) => {

  const context = useContext(PageSettings);

  const isFindId = type == 'id';

  const [value, setValue] = useState('');

  const onFind = () => {
    if (value == '') {
      context.showAlert(isFindId ? strings.side_menu.enter_phone_number : strings.signup.email_placeholder);
      return;
    }

    if (isFindId) {
      context.post(
        'auth/findId',
        { phone: value },
        response => {
          console.log(response.email);
          context.showAlert('회원님의 아이디는\n' + response.email + '\n입니다.', () => history.goBack())
        }
      );
    } else {
      context.post(
        'auth/findPwd',
        { email: value },
        response => {
          context.showAlert('회원님의 임시비밀번호는 ' + response.tmp_pwd + ' 입니다.', () => history.goBack())
        }
      );
    }
  }

  return (
    <Container className='find'>
      <TopBar title={isFindId ? strings.find.find_id : strings.find.find_pwd} />
      <div className='content'>
        {/*ID*/}
        <label className='caption'>{isFindId ? strings.find.phone : strings.signup.id}</label>
        <div className='input-wrapper'>
          <input type={isFindId ? 'tel' : 'email'} value={value}
                 placeholder={isFindId ? strings.side_menu.enter_phone_number : strings.signup.email_placeholder}
                 onChange={e => setValue(e.target.value)} />
        </div>
      </div>
      <button className='btn-confirm' onClick={onFind}>{strings.find.find}</button>
    </Container>
  );
}

export default withRouter(Find);
