import React, { useContext, useEffect, useState } from 'react';

import { PageSettings } from '../../../config/page-settings';
import Container from "../../../components/control/container";
import TopBar from "../../../components/control/top-bar";
import strings from "../../../lang/strings";

const NoticeDetail = ({ id }) => {

  const context = useContext(PageSettings);

  const [info, setInfo] = useState(null);

  useEffect(() => {
    getInfo();
  }, []);

  const getInfo = () => {
    if (id < 1) {
      return;
    }

    context.get(
      `support/notice/detail/${id}`,
      {},
      response => {
        setInfo(response);
      }
    );
  }

  return (
    <Container className='notice-detail'>
      <TopBar center={true} title={strings.side_menu.notice_event} />
      <div className='content'>
        <h3 className='title'>
          {info?.type == 'N' && <span className='notice-badge'>{strings.side_menu.notice}</span>}
          {info?.title}
        </h3>
        <span className='time'>{info?.create_date}</span>
        <p className='content' dangerouslySetInnerHTML={{__html: info?.content || ''}} />
        {
          info?.file != '' &&
          <img src={info?.file?.getFullUrl()} alt='image' />
        }
      </div>
    </Container>
  );
}

export default NoticeDetail;
