import React, { useContext, useEffect, useState } from 'react';

import { PageSettings } from '../../config/page-settings';
import Container from "../../components/control/container";
import strings from "../../lang/strings";
import '../../scss/pages/main.scss';
import Home from "./home/home";
import NearBy from "./nearby/nearby";
import Gift from "./gift/gift";
import MyPage from "./mypage/mypage";
import SideMenu from "../../components/control/side-menu";
import { storage } from "../../mobx/store";
import { inject, observer } from "mobx-react";
import { useHistory } from 'react-router-dom/cjs/react-router-dom';
import Sheet from "react-modal-sheet";
import { Map, MapMarker } from "react-kakao-maps-sdk";
import { DEFAULT_POSITION } from '../../config/const';

const Main = ({ rootStore }) => {

  const history = useHistory();
  const context = useContext(PageSettings);

  const { kakao } = window;
  const geocoder = new kakao.maps.services.Geocoder();

  const tabs = [
    { title: strings.gnb.home, type: 'home', component: () => <Home />, show: true },
    { title: strings.gnb.near_by, type: 'nearby', component: () => <NearBy />, show: true },
    {
      title: strings.gnb.t_gift,
      type: 'gift',
      component: () => <Gift />,
      show: rootStore.isSignedIn && rootStore.getMe?.level?.gift == 1
    },
    { title: strings.gnb.my_page, type: 'mypage', component: () => <MyPage />, show: true },
  ];
  const [activeTab, setActiveTab] = useState(storage.getTab());
  const [showSideMenu, toggleSideMenu] = useState(false);
  const [address, setAddress] = useState('지역');
  const [latitude, setLatitude] = useState('');
  const [longitude, setLongitude] = useState('');
  const [isAreaSelectOpen, setAreaSelectOpen] = useState(false);
  const [isMapOpen, setMapOpen] = useState(false);
  const [mapCenter, setMapCenter] = useState({
    lat: DEFAULT_POSITION.LAT, lng: DEFAULT_POSITION.LNG
  })

  useEffect(() => {
    if (rootStore.isSignedIn) {
      context.getMyInfo();
    }

    if (context.getOSType() == 'ios') {
      window.addEventListener('message', handleMessage);
    } else {
      document.addEventListener('message', handleMessage);
    }

    if (rootStore.getAddress == null || rootStore.getAddress == '') {
      getCurrentPosition();
    } else {
      setAddress(rootStore.getAddress);
      setLatitude(rootStore.getLatitude);
      setLongitude(rootStore.getLongitude);
    }

    if (rootStore.isSignedIn) {
      getFcmToken();
    }

    return () => {
      if (context.getOSType() == 'ios') {
        window.removeEventListener('message', handleMessage);
      } else {
        document.removeEventListener('message', handleMessage);
      }
    };
  }, []);

  const onHamburger = () => toggleSideMenu(prev => !prev);

  const onLogo = () => {
    // 로고 클릭시 홈 화면으로 이동
    onTab(0);
  }

  const onSearch = () => {
    history.push(`/search`);
  }

  const onMap = () => {
    history.push(`/map`);
  }

  const onTab = idx => {
    storage.setTab(idx);
    setActiveTab(idx);
  }

  const handleMessage = (event) => {
    if (typeof event?.data != "object" && event?.data.isJSON()) {
      const data = JSON.parse(event?.data);
      if (data.type == 'goBack') {
        if (storage.getTab() != 0) {
          onTab(0);
        } else {
          let obj = {
            func: 'exitApp',
            params: {}
          };

          let message = JSON.stringify(obj);
          window.ReactNativeWebView.postMessage(message);
        }
      } else if (data.type == 'readNfcTag') {
        visitFacility(data.nfc);
      } else if (data.type == 'currentLocation') {
        setAddressFromCoord(data.latitude, data.longitude);
      } else if (data.type == 'receiveGift') {
        receiveGift(data.senderId, data.giftDate);
      } else if (data.type == 'cbFCMToken') {
        updateFcmToken(data.fcmToken);
      }
    }
  }

  const visitFacility = (record) => {
    if (!rootStore.isSignedIn) {
      context.showConfirm(
        '',
        '로그인 후 이용해주세요.',
        strings.signup.confirm,
        ''
      );
      return;
    }
    context.post(
      'facility/visit_check',
      {
        nfc: record
      },
      response => {
        const facility = response.facility;
        // if (rootStore.isSignedIn && rootStore.getMe?.level == null) {
        //   context.showConfirm(
        //     '',
        //     '정회원만 이용가능합니다.',
        //     strings.signup.confirm,
        //     ''
        //   );
        //   return;
        // }

        // if (rootStore.getMe?.level > facility.level) {
        //   context.showConfirm(
        //     '',
        //     '이용중인 멤버쉽으로 방문 불가능한 시설입니다.',
        //     strings.signup.confirm,
        //     ''
        //   );
        //   return;
        // }

        if (facility.services.length > 0) {
          history.push(`/nfc/${facility.id}`);
        } else {
          context.post(
            'facility/visit',
            {
              nfc: record,
              pay_type: '',
              service_id: 0
            },
            response => {
              context.showConfirm(
                '',
                strings.nfc.visit_confirmed,
                strings.signup.confirm,
                ''
              );
            }
          );
        }
      }
    );
  }

  const getFcmToken = () => {
    if (window.ReactNativeWebView) {
      let obj = {
        func: 'onFCMToken',
        params: {}
      };

      let message = JSON.stringify(obj);
      window.ReactNativeWebView.postMessage(message);
    }
  }

  const updateFcmToken = (fcmToken) => {
    context.post(
      'member/updateFcmToken',
      {
        fcm_token: fcmToken
      },
      response => {
      }
    );
  }

  const getCurrentPosition = () => {
    if (window.ReactNativeWebView) {
      let obj = {
        func: 'onCurrentLocation',
        params: {}
      };

      let message = JSON.stringify(obj);
      window.ReactNativeWebView.postMessage(message);
    }
  }

  const setAddressFromCoord = (lat, lng) => {
    setLatitude(lat);
    setLongitude(lng);
    setMapCenter({ lat: lat, lng: lng });
    geocoder.coord2Address(lng, lat, (result, status) => {
      console.log(result);
      if (status == "OK" && result.length > 0) {
        // const address = result[0].address.address_name;
        const address = result[0].address.region_1depth_name + ' ' + result[0].address.region_2depth_name + ' ' + result[0].address.region_3depth_name;
        setAddress(address)
        // storage.setSelectedPosition(address, lat, lng);
        rootStore.setAddress(address);
        rootStore.setLatitude(lat);
        rootStore.setLongitude(lng);
      }
    })
  }

  const receiveGift = (sender, date) => {
    context.post(
      'gift/receive',
      {
        sender,
        date
      },
      response => {
      });
  }

  return (
    <Container className='main'>
      <div className='main-top-bar'>
        {/*<button className='btn-hamburger' onClick={onHamburger}>
          <img srcSet={'./assets/images/icon_menu.png 3x'} />
        </button>*/}
        <div className='text-logo' onClick={onLogo}>
          합티
        </div>
        <div className='area' onClick={() => setAreaSelectOpen(true)}>
          {address}
          <img src='assets/images/icon_arrow_down_line.png' style={{ width: 10, marginLeft: 5 }} />
        </div>
        <button className='btn-map' onClick={onMap}>
          <img srcSet={'./assets/images/icon_map.png 3x'} />
        </button>
        <button className='btn-search' onClick={onSearch}>
          <img srcSet={'./assets/images/icon_search_black.png 3x'} />
        </button>
      </div>

      <div className='main-content'>
        {tabs.filter(it => it.show)[activeTab]?.component()}
      </div>

      <div className='gnb'>
        {
          tabs.filter(it => it.show).map((tab, idx) => {
            const gnbImage = 'assets/images/icon_menu_' + tab.type + (activeTab == idx ? '_active' : '') + '.png'
            return <button key={idx} className={'gnb-tab' + (activeTab == idx ? ' active' : '')}
              onClick={() => onTab(idx)}>
              <img src={gnbImage} />
              {tab.title}
            </button>
          })
        }
      </div>
      <SideMenu
        active={showSideMenu}
        onClose={() => toggleSideMenu(prev => !prev)} />
      <Sheet
        isOpen={isAreaSelectOpen}
        onClose={() => setAreaSelectOpen(false)}
        snapPoints={[150]}>
        <Sheet.Container>
          <Sheet.Header>
            <div className='modal-sheet-header'>
              <div className='left' onClick={() => setAreaSelectOpen(false)}>
                <img src='assets/images/icon_close.png' />
              </div>
              <label className='center'>내 위치 지정</label>
              <div className='right'>
              </div>
            </div>
          </Sheet.Header>
          <Sheet.Content>
            <>
              <div className='sheet-select-div' onClick={() => {
                setAreaSelectOpen(false)
                getCurrentPosition()
              }}>현재 위치로 설정</div>
              <div className='sheet-select-div' onClick={() => {
                setAreaSelectOpen(false)
                setMapOpen(true);
              }}>지도에서 위치 지정</div>
            </>
          </Sheet.Content>
        </Sheet.Container>
        <Sheet.Backdrop onClick={() => setAreaSelectOpen(false)} />
      </Sheet>
      <Sheet
        isOpen={isMapOpen}
        onClose={() => setMapOpen(false)}
        disableDrag>
        <Sheet.Container>
          <Sheet.Header>
            <div className='modal-sheet-header'>
              <div className='left' onClick={() => setMapOpen(false)}>
                <img src='assets/images/icon_close.png' />
              </div>
              <label className='center'>지도에서 위치 지정</label>
              <div className='right' onClick={() => setMapOpen(false)}>
                확인
              </div>
            </div>
          </Sheet.Header>
          <Sheet.Content>
            <>
              <Map
                center={mapCenter}
                style={{ width: "100%", height: "100%" }}
                onClick={(_t, mouseEvent) => {
                  setAddressFromCoord(mouseEvent.latLng.getLat(), mouseEvent.latLng.getLng());
                }}>
                <MapMarker // 마커를 생성합니다
                  position={{
                    // 마커가 표시될 위치입니다
                    lat: latitude,
                    lng: longitude,
                  }}
                />
              </Map>
            </>
          </Sheet.Content>
        </Sheet.Container>
        <Sheet.Backdrop onClick={() => setMapOpen(false)} />
      </Sheet>
    </Container>
  );
}

export default (inject('rootStore')(observer(Main)));
