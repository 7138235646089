import React from "react";

import Login from "../pages/auth/login";
import Signup from "../pages/auth/signup";
import Intro from "../pages/auth/intro";
import Main from "../pages/main/main";
import FacilityDetail from "../pages/main/home/facility-detail";
import Reservation from "../pages/main/home/reservation";
import Nfc from "../pages/main/home/nfc";
import AccountInfo from "../pages/main/mypage/account-info";
import Faq from "../pages/main/mypage/faq";
import PartnershipInquiry from "../pages/main/mypage/inquiry";
import MembershipPayment from "../pages/main/mypage/membership-payment";
import MembershipLevel from "../pages/main/mypage/membership-level";
import Notice from "../pages/main/mypage/notice";
import TicketUsageHistory from "../pages/main/mypage/ticket-usage-history";
import Withdraw from "../pages/main/mypage/withdraw";
import Find from "../pages/auth/find";
import NoticeDetail from "../pages/main/mypage/notice-detail";
import Splash from "../pages/auth/splash";
import TrainerDetail from "../pages/main/home/trainer-detail";
import Search from "../pages/main/home/search";
import MapPage from "../pages/main/home/map";
import ChangePhone from "../pages/auth/change-phone";

const Menu = [
  {
    path: '/', title: '', exact: true, private: false,
    component: () => <Intro />
  },
  {
    path: '/intro', title: '', exact: true, private: false,
    component: () => <Intro />
  },
  {
    path: '/login', title: '', exact: true, private: false,
    component: () => <Login />
  },
  {
    path: '/find/:type', title: '', exact: true, private: false,
    component: ({ match }) => <Find type={match.params.type} />
  },
  {
    path: '/change-phone', title: '', exact: true, private: false,
    component: () => <ChangePhone />
  },
  {
    path: '/signup', title: '', exact: true, private: false,
    component: () => <Signup />
  },
  {
    path: '/facility/:id', title: '', exact: true, private: true,
    component: ({ match }) => <FacilityDetail id={match.params.id} />
  },
  {
    path: '/trainer/:id', title: '', exact: true, private: true,
    component: ({ match }) => <TrainerDetail id={match.params.id} />
  },
  {
    path: '/faq', title: '', exact: true, private: true,
    component: () => <Faq />
  },
  {
    path: '/notice', title: '', exact: true, private: true,
    component: () => <Notice />
  },
  {
    path: '/notice/:id', title: '', exact: true, private: true,
    component: ({ match }) => <NoticeDetail id={match.params.id} />
  },
  {
    path: '/main', title: '', exact: true, private: true,
    component: () => <Main />
  },
  {
    path: '/reservation/:id', title: '', exact: true, private: true,
    component: ({ match }) => <Reservation id={match.params.id} />
  },
  {
    path: '/nfc/:id', title: '', exact: true, private: true,
    component: ({ match }) => <Nfc id={match.params.id} />
  },
  {
    path: '/account-info', title: '', exact: true, private: true,
    component: () => <AccountInfo />
  },
  {
    path: '/inquiry', title: '', exact: true, private: true,
    component: () => <PartnershipInquiry />
  },
  {
    path: '/membership/level', title: '', exact: true, private: true,
    component: () => <MembershipLevel />
  },
  {
    path: '/membership/payment/:id', title: '', exact: true, private: true,
    component: ({ match }) => <MembershipPayment id={match.params.id} />
  },
  {
    path: '/ticket-log', title: '', exact: true, private: true,
    component: () => <TicketUsageHistory />
  },
  {
    path: '/withdraw', title: '', exact: true, private: true,
    component: () => <Withdraw />
  },
  {
    path: '/search', title: '', exact: true, private: true,
    component: () => <Search />
  },
  {
    path: '/map', title: '', exact: true, private: true,
    component: () => <MapPage />
  },
]

export default Menu;
