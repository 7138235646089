import React, { useContext, useState } from 'react';

import { PageSettings } from '../../../config/page-settings';
import Container from "../../../components/control/container";
import TopBar from "../../../components/control/top-bar";
import strings from "../../../lang/strings";
import { FORMAT, IS_UI_MODE } from "../../../config/const";
import { withRouter } from "react-router-dom";
import dayjs from "dayjs";
import { inject } from "mobx-react";
import { storage } from "../../../mobx/store";

const Withdraw = ({ history, rootStore }) => {

  const context = useContext(PageSettings);

  const [content, setContent] = useState('');
  const [checked, setChecked] = useState(false);

  const onConfirm = () => {
    if (IS_UI_MODE) {
      history.goBack();
      return;
    }

    if (content == '') {
      context.showAlert(strings.my_page.enter_content);
      return;
    }
    if (!checked) {
      return;
    }

    // 회원탈퇴
    context.post(
      'member/leave',
      {
        content
      },
      () => {
        context.showAlert(strings.my_page.withdraw_success, () => {
          storage.setTab(0);
          storage.removeAutoLogin();
          rootStore.signOut();
          history.push('/intro');
        });
      }
    );
  }

  return (
    <Container className='withdraw'>
      <TopBar center={true} title={strings.my_page.withdraw} />
      <div className='content'>
        <label className='guide1'>{strings.my_page.withdrawal_application}</label>
        <label className='guide2'>
          <span>{strings.formatString(strings.my_page.today, dayjs().format(FORMAT.DATE))}</span>
          {strings.my_page.withdraw_membership}
        </label>
        <hr />
        <div className='wrapper'>
          <textarea className='reason' placeholder={strings.my_page.enter_content} value={content}
                    onChange={e => setContent(e.target.value)} />
          <label className='confirm'>{strings.my_page.like_withdraw}</label>
          <hr />
          <button className='btn-check' onClick={() => setChecked(prev => !prev)}>
            <img srcSet={checked ?
              './assets/images/icon_checkbox_checked.png 3x' :
              './assets/images/icon_checkbox_unchecked.png 3x'
            } />
            {strings.my_page.will_withdraw}
          </button>
        </div>
        <button className='btn-withdraw' onClick={onConfirm}>{strings.signup.confirm}</button>
      </div>
    </Container>
  );
}

export default withRouter(inject('rootStore')(Withdraw));
