import React, { useContext, useEffect, useRef, useState } from 'react';

import { PageSettings } from '../../../config/page-settings';
import Container from "../../../components/control/container";
import TopBar from "../../../components/control/top-bar";
import { withRouter } from "react-router-dom";
import { rootStore } from '../../../mobx/store';
import { DEFAULT_POSITION } from '../../../config/const';

import { CustomOverlayMap, Map, MapMarker } from "react-kakao-maps-sdk";

const MapPage = ({ id, history }) => {
  const context = useContext(PageSettings);

  const [mapCenter, setMapCenter] = useState({
    lat: DEFAULT_POSITION.LAT, lng: DEFAULT_POSITION.LNG
  })

  const [facilityList, setFacilityList] = useState([]);
  const [selectedFacility, setSelectedFacility] = useState(null);

  useEffect(() => {
    if (context.getOSType() == 'ios') {
      window.addEventListener('message', handleMessage);
    } else {
      document.addEventListener('message', handleMessage);
    }

    getCurrentPosition();
    getFacilityList();

    return () => {
      if (context.getOSType() == 'ios') {
        window.removeEventListener('message', handleMessage);
      } else {
        document.removeEventListener('message', handleMessage);
      }
    };
  }, []);

  const handleMessage = (event) => {
    if (typeof event?.data != "object" && event?.data.isJSON()) {
      const data = JSON.parse(event?.data);
      if (data.type == 'currentLocation') {
        setMapCenter({
          lat: data.latitude, lng: data.longitude
        });
        console.log('map page my position >>>>>', data.latitude, data.longitude);
      }
    }
  }

  const getCurrentPosition = () => {
    if (window.ReactNativeWebView) {
      let obj = {
        func: 'onCurrentLocation',
        params: {}
      };

      let message = JSON.stringify(obj);
      window.ReactNativeWebView.postMessage(message);
    }
  }

  const getFacilityList = () => {
    // 시설리스트
    context.get(
      'facility/search',
      {
        keyword: '',
        offset: 0,
        limit: 20
      },
      response => {
        setFacilityList(response.list);
      }
    );
  }

  return (
    <Container className='map'>
      <TopBar center={true} title={'지도로 보기'} />
      <div className='content'>
        <div className='body'>
          <Map
            center={mapCenter}
            style={{ width: "100%", height: "100%" }}>
            {/* <MapMarker
              position={mapCenter}
            /> */}
            {
              selectedFacility != null &&
              <CustomOverlayMap position={{ lat: selectedFacility.lat, lng: selectedFacility.lng }}>
                <div className='wrap'>
                  <div className="info">
                    <div className="title">{selectedFacility.name}
                      <div className="close" title="닫기" onClick={() => setSelectedFacility(null)}></div>
                    </div>
                    <div className="body">
                      <div className="img">
                        <img src={selectedFacility?.photo?.getImages(true)} width="73" height="70" alt={selectedFacility.name} />
                      </div>
                      <div className="desc">
                        <div className="ellipsis">{selectedFacility.address}</div>
                        <div className="jibun ellipsis">{selectedFacility.address_detail}</div>
                        <div style={{ marginTop: 10 }}>
                          <button className='link' onClick={() => history.push(`/facility/${selectedFacility.id}`)}>
                            이동하기
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </CustomOverlayMap>
            }
            {
              facilityList.map((facility, index) => (
                <MapMarker
                  key={`${facility.name}-${index}`}
                  position={{
                    lat: facility.lat, lng: facility.lng
                  }} // 마커를 표시할 위치
                  // image={{
                  //   src: "https://t1.daumcdn.net/localimg/localimages/07/mapapidoc/markerStar.png", // 마커이미지의 주소입니다
                  //   size: {
                  //     width: 24,
                  //     height: 35
                  //   }, // 마커이미지의 크기입니다
                  // }}
                  clickable={true}
                  title={facility.name} // 마커의 타이틀, 마커에 마우스를 올리면 타이틀이 표시됩니다
                  onClick={(marker) => {
                    // 마커 클릭
                    setSelectedFacility(facility);
                  }}
                />
              ))}
          </Map>
        </div>
      </div>
    </Container>
  );
}

export default withRouter(MapPage);
