import React, { useContext, useEffect, useState } from 'react';
import { withRouter } from 'react-router-dom';

import { PageSettings } from '../../config/page-settings';
import Container from "../../components/control/container";

import '../../scss/pages/signup.scss';
import TopBar from "../../components/control/top-bar";
import strings from "../../lang/strings";
import SignupMainInfo from "./fragment/signup-main-info";
import SignupGenderAge from "./fragment/signup-gender-age";
import SignupCategory from "./fragment/signup-category";
import SignupArea from "./fragment/signup-area";
import SignupProfile from "./fragment/signup-profile";
import { LOGIN_TYPE } from "../../config/const";
import { inject } from "mobx-react";
import { storage } from "../../mobx/store";

const Signup = ({ history, rootStore }) => {

  const context = useContext(PageSettings);

  const [step, setStep] = useState(1);

  const fragments = [
    { step: 1, fragment: (next) => <SignupMainInfo onNext={next} /> },
    { step: 2, fragment: () => <SignupGenderAge /> },
    { step: 3, fragment: () => <SignupCategory /> },
    { step: 4, fragment: () => <SignupArea /> },
    { step: 5, fragment: (next) => <SignupProfile onNext={next} /> },
  ];

  useEffect(() => {
    let messageListener;
    if (context.getOSType() == 'ios') {
      messageListener = window.addEventListener('message', handleMessage);
    } else {
      messageListener = document.addEventListener('message', handleMessage);
    }

    if (window.ReactNativeWebView) {
      getDeviceId();
    }

    return messageListener;
  }, []);

  const handleMessage = (event) => {
    if (typeof event?.data != "object" && event?.data.isJSON()) {
      const data = JSON.parse(event?.data);
      if (data.type == 'setDeviceId') {
        context.handleSetDeviceId(data.deviceId);
      }
    }
  }

  const onBack = () => {
    if (step > 1) {
      setStep(prev => prev - 1);
      return;
    }
    storage.removeSignupUser();
    history.goBack();
  }

  const canGoNext = () => {
    const userInfo = storage.getSignupUser();
    switch (step) {
      case 1:
        return '';
      case 2:
        return ((userInfo?.gender ?? '') != '' && (userInfo?.age ?? 0) > 0) ? '' : strings.signup.select_gender_age;
      case 3:
        return ((userInfo?.category ?? []).length > 0) ? '' : strings.signup.select_category;
      case 4:
        return ((userInfo?.area ?? []).length > 0) ? '' : strings.signup.select_area;
      case 5:
        return '';
      default:
        return false;
    }
  }

  const onNext = () => {
    const msg = canGoNext();
    if (msg != '') {
      context.showAlert(msg);
      return;
    }

    if (step >= fragments.length) {
      signup();
      return;
    }
    setStep(prev => prev + 1);
  }

  const signup = () => {
    // 회원가입시도
    const user = storage.getSignupUser();
    context.post(
      'auth/signup',
      {
        ...user,
        device_id: context.deviceId
      },
      response => {
        storage.removeSignupUser();
        storage.setAutoLogin(user.login_type, user.login_type == LOGIN_TYPE.EMAIL ? user.email : user.sns_id, user.pwd);
        rootStore.signIn(response.token);
        history.push('/main');
      }
    );
  }

  const getDeviceId = () => {
    let obj = {
      func: 'getDeviceId',
      params: {}
    };

    let message = JSON.stringify(obj);
    window.ReactNativeWebView.postMessage(message);
  }

  return (
    <Container className='signup'>
      <TopBar
        title={step == 1 ? strings.signup.member_info : strings.signup.prev}
        onBack={onBack}
        rightView={() => (step == 1 || step >= fragments.length) ? null : (
          <button className='btn-next' onClick={onNext}>{strings.signup.next}</button>
        )} />

      <div className='content'>
        {fragments.find(it => it.step == step).fragment(onNext)}
      </div>
    </Container>
  );
}

export default withRouter(inject('rootStore')(Signup));
