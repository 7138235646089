import React, { useContext, useEffect, useRef, useState } from 'react';

import { PageSettings } from '../../../config/page-settings';
import Container from "../../../components/control/container";
import TopBar from "../../../components/control/top-bar";
import { withRouter } from "react-router-dom";
import { DEFAULT_POSITION, FACILITY_SERVICES } from "../../../config/const";
import strings from "../../../lang/strings";
import { inject } from "mobx-react";

// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/free-mode";
import "swiper/css/pagination";

// import required modules
import { FreeMode, Pagination } from "swiper";
import dayjs from "dayjs";

import { Map, MapMarker } from "react-kakao-maps-sdk";
import ImagePopup from '../../popup/image-popup';

const FacilityDetail = ({ id, history, rootStore }) => {
  const context = useContext(PageSettings);

  const [viewMode, setViewMode] = useState(1);
  const [info, setInfo] = useState(null);
  const [reviewList, setReviewList] = useState([]);
  const [loadingEnd, setLoadingEnd] = useState(false);
  const [fixedTab, setFixedTab] = useState(false);
  const [showImage, setShowImage] = useState(false);
  const [selectedImageUrl, setSelectedImageUrl] = useState('');

  const [mapCenter, setMapCenter] = useState({
    lat: DEFAULT_POSITION.LAT, lng: DEFAULT_POSITION.LNG
  })

  const refMapDiv = useRef(null);

  useEffect(() => {
    if (id) {
      getInfo();
    }
  }, []);

  useEffect(() => {
    if (info != null) {
      window.addEventListener('scroll', handleScroll);
    }

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [info])

  const getInfo = () => {
    // 시설상세정보얻기
    context.get(
      `facility/detail/${id}`,
      {},
      response => {
        setInfo(response);
        setMapCenter({ lat: response.lat, lng: response.lng })
      }
    );
  }

  const handleScroll = (event) => {
    if (info?.photo != null && info?.photo != '') {
      setFixedTab(window.scrollY > 254);
    } else {
      setFixedTab(window.scrollY > 0);
    }
  };

  const getReviewList = (refresh = false) => {
    if (!refresh && loadingEnd) {
      return;
    }

    // 리뷰리스트얻기
    context.get(
      'facility/review/list',
      {
        id,
        offset: refresh ? 0 : reviewList.length,
        limit: 20
      },
      response => {
        setReviewList(prev => refresh ? response.list : prev.concat(response.list));
        setLoadingEnd(!response.loadMore);
      }
    );
  }

  const onReserve = () => {
    if (!rootStore.isSignedIn) {
      context.showConfirm(
        strings.my_page.go_to_login,
        '',
        strings.signup.confirm,
        strings.my_page.cancel,
        response => {
          if (response == true) {
            history.push('/intro');
          }
        }
      );
      return;
    }

    // 정회원여부 및 사용가능한 티켓 체크
    if (rootStore.getMe?.level == null) {
      context.showConfirm(
        '멤버십가입후 사용가능합니다.',
        '',
        strings.signup.confirm,
        strings.my_page.cancel,
        response => {
          if (response == true) {
            history.push('/membership/level');
          }
        }
      );
      return;
    }

    if ((rootStore.getMe?.ticket.total_count || 0) - (rootStore.getMe?.ticket.use_count || 0) <= 0) {
      context.showAlert('사용가능한 티켓이 없습니다.');
      return;
    }
    
    // 예약가능한 시간대가 설정되어 있는 경우에만 예약화면으로 이동
    if (info?.reservation_time == '') {
      context.showAlert('예약가능한 시간이 없습니다.');
      return;
    }

    history.push(`/reservation/${id}`);
  };

  const openUrl = (url) => {
    if (window.ReactNativeWebView) {
      let obj = {
        func: 'openUrl',
        params: {
          url: url
        }
      };

      let message = JSON.stringify(obj);
      window.ReactNativeWebView.postMessage(message);
    } else {
      window.open(url, "_blank", "noreferrer");
    }
  }

  const onLocation = () => {
    refMapDiv?.current.scrollIntoView({ behavior: 'smooth' });
  }

  const onCall = () => {
    const phoneNumber = info?.phone || '';
    if (phoneNumber != '') {
      if (window.ReactNativeWebView) {
        let obj = {
          func: 'callPhone',
          params: {
            phone: phoneNumber
          }
        };

        let message = JSON.stringify(obj);
        window.ReactNativeWebView.postMessage(message);
      }
    }
  }

  const onTrainer = (trainer) => {
    // 트레이너 상세로 이동
    console.log(trainer);
    history.push(`/trainer/${trainer.id}`);
  }

  const onRegisterMembership = () => {
    if (!rootStore.isSignedIn) {
      context.showConfirm(
        strings.my_page.go_to_login,
        '',
        strings.signup.confirm,
        strings.my_page.cancel,
        response => {
          if (response == true) {
            history.push('/intro');
          }
        }
      );
      return;
    }

    history.push('/membership/level');
  }

  const onImage = (url) => {
    // 이미지 크게 보기 팝업
    setSelectedImageUrl(url);
    setShowImage(true);
  }

  return (
    <Container className='facility-detail'>
      <TopBar center={true} title={info?.name || ''} />

      <div className='content'>
        <Swiper
          initialSlide={0}
          slidesPerView={1}
          spaceBetween={0}
          // loop={true}
          // loopFillGroupWithBlank={true}
          pagination={{
            clickable: true,
          }}
          modules={[FreeMode, Pagination]}
          className="banner">
          {
            info?.photo?.getImages(false).map((it, idx) => (
              <SwiperSlide key={idx}>
                <div className='item' onClick={() => onImage(it)}>
                  <img src={it} alt='image' />
                </div>
              </SwiperSlide>
            ))
          }
        </Swiper>
        <div className={'tabs' + (fixedTab ? ' fixed' : '') }>
          <button className={'tab-btn' + (viewMode == 1 ? ' active' : '')} onClick={() => setViewMode(1)}>
            {strings.home.facility_info}
          </button>
          <button className={'tab-btn' + (viewMode == 2 ? ' active' : '')} onClick={() => {
            setViewMode(2);
            getReviewList(true);
          }}>
            {strings.home.review}
          </button>
          <button className={'tab-btn' + (viewMode == 3 ? ' active' : '')} onClick={() => {
            setViewMode(3);
          }}>
            {'서비스'}
          </button>
        </div>
        {
          viewMode == 1 &&
          <div>
            <div className='facility-info'>
              <div className='first'>
                <div className='icon heart'>
                  <img srcSet={'../assets/images/icon_heart.png 3x'} alt='heart' />
                </div>
                <label className='name'>{info?.name}</label>
                <div className='stars'>
                  {
                    [...Array(5).keys()].map((it, idx) => (
                      <img key={idx} srcSet={'../assets/images/icon_star.png 3x'} alt='star' />
                    ))
                  }
                </div>
                {/*<label className='badge' onClick={() => history.push(`/nfc/${id}`)}>
              {context.getSettingList('area').find(it => it.id == info?.area)?.name}
            </label>*/}
                <label className='badge'>
                  {context.getSettingList('level').find(it => it.id == info?.level)?.name}
                </label>
              </div>
              <div className='second' onClick={onLocation}>
                <div className='icon'>
                  <img srcSet={'../assets/images/icon_location.png 3x'} alt='location' />
                </div>
                <label>{info?.address || ''}</label>
              </div>
              <div className='second' onClick={onCall}>
                <div className='icon'>
                  <img srcSet={'../assets/images/icon_phone.png 3x'} alt='location' />
                </div>
                <label>{info?.phone || ''}</label>
              </div>
            </div>

            <button className='btn-reserve' onClick={onReserve}>{info?.reservation || strings.home.make_reservation}</button>

            {
              info?.trainers && info?.trainers.length > 0 &&
              <div className='extra-info'>
                <div className='header'>{'1:1 PT'}</div>
                <div className='body'>
                  {
                    info?.trainers.map((trainer, idx) => (
                      <button key={idx} className='trainer-info' onClick={() => { onTrainer(trainer) }}>
                        <img className='trainer-image' src={trainer.image.getImages()} alt='trainer' />
                        <div className='trainer-mask'>
                          <div className='trainer-name'>{trainer.name}</div>
                        </div>
                      </button>
                    ))
                  }
                </div>
              </div>
            }

            <div className='extra-info'>
              <div className='header'>{strings.home.used_items}</div>
              <div className='body'>
                {context.getSettingList('category').filter(it => info?.category?.split(',')?.includes(`${it.id}`)).map(it => it.name).join(', ') || ''}
              </div>
            </div>
            <div className='extra-info'>
              <div className='header'>{strings.home.user_guide}</div>
              <div className='body' dangerouslySetInnerHTML={{ __html: info?.guide || '' }} />
            </div>
            {
              info?.service && info?.service != '' &&
              <div className='extra-info'>
                <div className='header'>{'편의시설'}</div>
                <div className='body'>
                  <div className='service-list'>
                    {
                      info?.service.split(',').map((service_id, idx) => (
                        <div key={idx} className='service-item'>
                          <img src={'../' + FACILITY_SERVICES[service_id - 1].image} />
                          <p>{FACILITY_SERVICES[service_id - 1].name}</p>
                        </div>
                      ))
                    }
                  </div>
                </div>
              </div>
            }
            {
              info?.sns_url &&
              <div className='extra-info'>
                <div className='header'>{'바로가기'}</div>
                <div className='body'>
                  {
                    info?.sns_url.split('|').filter(it => it != '').map((it, idx) => (
                      <div key={idx} className='sns-link' onClick={() => { openUrl(it) }}>
                        <img src={'../assets/images/icon_' + (it.indexOf('instagram') > 0 ? 'insta' : 'blog') + '.png'} style={{ width: 40 }} />
                      </div>
                    ))
                  }
                </div>
              </div>
            }
            <div ref={refMapDiv} className='extra-info'>
              <div className='header'>{'위치'}</div>
              <div className='body'>
                <Map
                  center={mapCenter}
                  style={{ width: "100%", height: "300px" }}>
                  <MapMarker
                    position={mapCenter}
                  />
                </Map>
              </div>
            </div>
          </div>
        }
        {
          viewMode == 2 &&
          <div className='extra-info'>
            <div className='body'>
              {
                reviewList.length > 0 ?
                  <div className='review-list'>
                    {
                      reviewList.map((it, idx) => (
                        <div key={idx} className='item'>
                          <div className='review'>
                            <img className='user-profile' src={it.user.profile.getImages()} alt='profile' />
                            <div className='body'>
                              <div className='header'>
                                <label className='nickname'>{it?.user?.name || ''}</label>
                                <div className='stars'>
                                  {
                                    [...Array(5).keys()].map((it, idx) => (
                                      <img key={idx} srcSet={'../assets/images/icon_star.png 3x'} alt='star' />
                                    ))
                                  }
                                </div>
                                <label className='time'>{dayjs(it?.review_date).format('YYYY.MM.DD') || ''}</label>
                              </div>
                              <p
                                className='review-content'>{it?.content || ''}</p>
                            </div>
                          </div>
                          {
                            (it?.admin_comment ?? '') != '' &&
                            <div className='admin-comment'>
                              <div className='up-arrow' />
                              <div className='header'>
                                <label className='admin'>{strings.home.administrator}</label>
                                <label className='time'>{dayjs(it?.comment_date).format('YYYY.MM.DD') || ''}</label>
                              </div>
                              <p className='admin-content'>{it?.admin_comment || ''}</p>
                            </div>
                          }
                        </div>
                      ))
                    }
                  </div>
                  :
                  <div style={{ textAlign: 'center', marginTop: 40, marginBottom: 30 }}>{'아직 작성된 리뷰가 없습니다.'}</div>
              }
            </div>
          </div>
        }
        {
          viewMode == 3 &&
          <div></div>
        }
        {
          (!rootStore.isSignedIn || (rootStore.isSignedIn && rootStore.getMe?.level == null)) &&
          <button className='btn-signup' onClick={onRegisterMembership}>{strings.home.register_membership}</button>
        }
        {
          showImage &&
          <ImagePopup 
            active={showImage}
            url={selectedImageUrl}
            onClose={() => {
              setSelectedImageUrl('');
              setShowImage(false);
            }}
          />
        }
      </div>
    </Container>
  );
}

export default withRouter(inject('rootStore')(FacilityDetail));
