import React, { useContext, useEffect, useState } from 'react';
import { PageSettings } from "../../config/page-settings";

const AreaList = ({ onChange, selectData = [] }) => {

  const context = useContext(PageSettings);
  const [areaList, setAreaList] = useState([]);

  useEffect(() => {
    const originAreaList = context.getSettingList('area');
    const list = [];

    // originAreaList 에 3개당 하나씩 빈 배열을 삽입한다. 이때 id는 0으로 구분한다.
    // 이유는 상위지역선택시 하위지역을 그 바로 아래에 현시해주어야 하고 상위지역은 한줄에 2개씩 현시해주도록 되어 있기 때문이다.
    let fakeCell = {
      id: 0,
      name: '',
      selected: false,
      children: []
    };
    originAreaList.forEach((parent, idx) => {
      list.push({
        id: parent.id,
        name: parent.name,
        selected: false,
        children: JSON.parse(JSON.stringify(parent.children))
      });
      if (idx % 2 == 1) {
        list.push(JSON.parse(JSON.stringify(fakeCell)));
      }
    });
    if (originAreaList.length % 2 == 1) {
      list.push(JSON.parse(JSON.stringify(fakeCell)));
    }

    setAreaList(list);
  }, []);

  const onToggleParent = selectedIndex => {
    const selectedParent = areaList.find((it, idx) => idx == selectedIndex);
    const fakeCellIndex = areaList.findIndex((it, idx) => it.id == 0 && idx > selectedIndex);

    setAreaList(prev => prev.map((parent, idx) => {
      if (parent.id == 0) {
        // fake cell
        if (idx == fakeCellIndex && selectedParent.selected) {
          parent.children = selectedParent.children;
        } else {
          parent.children = [];
        }
      } else {
        // parent cell
        if (idx == selectedIndex) {
          parent.selected = !parent.selected;
        } else {
          parent.selected = false;
        }
      }
      return parent;
    }));
  }

  const onSelect = id => {
    onChange(id);
  }

  return (
    <div className='category-wrapper' style={{ marginTop: 10 }}>
      {
        areaList.map((parent, parentIdx) => (
          parent.id > 0 ?
            <button key={parentIdx}
                    className={'btn-category' + (parent.children.some(child => selectData.includes(child.id)) ? ' active' : '')}
                    onClick={() => onToggleParent(parentIdx)}>
              {parent.name}
            </button>
            :
            parent.children.length > 0 ?
              <div key={parentIdx} className='child-area-list'>
                {
                  parent.children.map((child, childIdx) => (
                    <button key={childIdx} onClick={() => onSelect(child.id)}>
                      <img srcSet={selectData.includes(child.id) ?
                        './assets/images/icon_checkbox_checked.png 3x' :
                        './assets/images/icon_checkbox_unchecked.png 3x'
                      } />
                      {child.name}
                    </button>
                  ))
                }
              </div>
              :
              null
        ))
      }
    </div>
  );
}

export default AreaList;
