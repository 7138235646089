import React, { useContext, useEffect } from 'react';
import { withRouter } from 'react-router-dom';

import { PageSettings } from '../../config/page-settings';
import Container from "../../components/control/container";

import '../../scss/pages/splash.scss';
import { inject } from "mobx-react";
import { storage } from "../../mobx/store";


const Splash = ({ history, rootStore }) => {
  const context = useContext(PageSettings);

  useEffect(() => {
    setTimeout(() => tryLogin(), 2000);
  }, []);

  const tryLogin = () => {
    const info = storage.getAutoLogin();
    if (info == null) {
      history.push('/intro');
      return;
    }

    context.post(
      'auth/login',
      {
        login_type: info.type,
        email: info.id,
        pwd: info.pwd,
        os: context.getOSType()
      },
      response => {
        rootStore.signIn(response.token);
        history.push('/main');
      },
      () => {
        history.push('/intro');
      }
    );
  }

  return (
    <Container className='splash'>
      <div className='logo-img'>
        <img src='assets/images/logo1.png' />
      </div>
    </Container>
  );
}

export default withRouter(inject('rootStore')(Splash));
