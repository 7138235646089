module.exports = {
  isLocalhost: true,
  API_URL: 'https://api.ticketshare.co.kr', // this.isLocalhost ? 'http://192.168.0.54:3005' : 'http://1.234.63.213:3005' : 'https://api.ticketshare.co.kr',

  KAKAO_CHANNEL_URL: 'http://pf.kakao.com/_xeWVMn',
  KAKAO_APP_KEY: 'c45e46f007663e74e5c69a2e7767d4ff',

  NAVER_CLIENT_ID: 'i_2Xr4k57TYNzjSiZxcF',
  NAVER_CALLBACK_URL: 'http://1.234.63.213:3000/intro',

  IS_UI_MODE: false,
  IS_OFFLINE_MODE: true,

  HEADER: {
    AUTH_TOKEN: 'x-access-token',
    CONTENT_TYPE: {
      NAME: 'Content-Type',
      JSON: 'application/json',
      FILE: 'multipart/form-data',
      FORM: 'application/x-www-form-urlencoded'
    },
    LANG: 'Accept-Language',
  },

  //. 각종 시간/일자 포맷
  FORMAT: {
    DATE_TIME: 'YYYY-MM-DD HH:mm:ss',
    DATE: 'YYYY-MM-DD',
    TIME: 'HH:mm:ss',
    MONTH: 'YYYY-MM',
    YEAR: 'YYYY'
  },

  //. 응답코드
  RESULT_CODE: {
    SUCCESS: 0,                     //. 정상
    EMPTY_TOKEN: -1,                //. 토큰정보 없음.
    TOKEN_EXPIRED: -2,              //. 토큰 기한 만료
    EMPTY_PARAM: -3,                //. 요청파라미터 누락
    ERROR: -10,                     //. api 내부 오류
  },

  //. 사용자 상태코드
  USER_STATUS: {
    LEAVE: -2,                      //. 탈퇴
    BLOCK: -1,                      //. 영구정지
    NORMAL: 1,                      //. 정상
  },

  //. 로그인타입
  LOGIN_TYPE: {
    KAKAO: 'K',
    NAVER: 'N',
    EMAIL: 'E'
  },

  //. 성별
  GENDER: {
    MALE: 'M',
    FEMALE: 'F'
  },

  //. 상태코드
  STATUS: {
    PENDING: 0,         //. 대기
    NORMAL: 1,          //. 정상
    REJECT: 2,          //. 반려
    DELETED: -1,        //. 삭제
    FINISH: 2,          //. 완료
    CANCEL: 2,          //. 취소
    REAPPLY: 3,         //. 재심요청
    YES: 'Y',
    NO: 'N'
  },

  // 편의시설
  FACILITY_SERVICES: [
    {id: 1, name: '운동복', image: 'assets/images/icon_service_cloth.png'},
    {id: 2, name: '수건', image: 'assets/images/icon_service_towel.png'},
    {id: 3, name: '샤워실', image: 'assets/images/icon_service_shower.png'},
    {id: 4, name: '라커', image: 'assets/images/icon_service_locker.png'},
    {id: 5, name: '와이파이', image: 'assets/images/icon_service_wifi.png'},
    {id: 6, name: '주차장', image: 'assets/images/icon_service_park.png'},
    {id: 7, name: '유료주차', image: 'assets/images/icon_service_park.png'},
    {id: 8, name: '체성분검사', image: 'assets/images/icon_service_body_composition.png'},
    {id: 9, name: '체형분석', image: 'assets/images/icon_service_body_type.png'},
    {id: 10, name: '음료판매', image: 'assets/images/icon_service_drink.png'},
    {id: 11, name: '반신욕', image: 'assets/images/icon_service_shower.png'},
    {id: 12, name: '안마의자', image: 'assets/images/icon_service_towel.png'},
    {id: 13, name: '사우나', image: 'assets/images/icon_service_shower.png'},
    {id: 14, name: '찜질방', image: 'assets/images/icon_service_cloth.png'},
  ],

  // 기본 위치좌표(강남역)
  DEFAULT_POSITION: {
    ADDRESS: '서울 강남구 역삼동',
    LAT: 37.498206,
    LNG: 127.027610
  }
}
