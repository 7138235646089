import React, { useContext, useEffect, useState } from 'react';

import { PageSettings } from '../../../config/page-settings';
import '../../../scss/pages/signup.scss';
import strings from "../../../lang/strings";
import { GENDER } from "../../../config/const";
import { storage } from "../../../mobx/store";

const SignupGenderAge = () => {

  const context = useContext(PageSettings);

  const [user, setUser] = useState(storage.getSignupUser());
  const [list, setList] = useState([]);

  useEffect(() => {
    const ageList = context.getSettingList('age');
    const data = [];
    ageList.forEach(age => {
      Object.keys(GENDER).reverse().forEach(gender => {
        data.push({ age, gender: GENDER[gender] });
      })
    });
    setList(data);
  }, []);

  useEffect(() => {
    storage.saveSignupUser(user);
  }, [user]);

  const onChange = (prop, value) => {
    if (typeof prop == "object") {
      setUser(prev => {
        return {
          ...prev,
          ...prop
        }
      });
      return;
    }
    setUser(prev => {
      return {
        ...prev,
        [prop]: value
      }
    });
  }

  const onSelect = (idx) => {
    const { age, gender } = list[idx];
    onChange({ age, gender });
  };

  return (
    <div className='gender-age'>
      <h3 className='caption'>{strings.signup.select_gender_age.nl2br()}</h3>
      <label className='guide'>{strings.signup.recommend_gender_age}</label>
      <div className='category-wrapper'>
        {
          list.map((it, idx) => (
            <button key={idx}
                    className={'btn-category' + (user?.age == it.age && user?.gender == it.gender ? ' active' : '')}
                    onClick={() => onSelect(idx)}>
              {strings.formatString(
                it.gender == GENDER.FEMALE ? strings.signup.age_woman : strings.signup.age_man,
                it.age
              )}
            </button>
          ))
        }
      </div>
    </div>
  );
}

export default SignupGenderAge;
