import React, { useContext, useEffect, useState } from 'react';

import { PageSettings } from '../../../config/page-settings';
import Container from "../../../components/control/container";
import '../../../scss/pages/home.scss';
import strings from "../../../lang/strings";
import { KAKAO_CHANNEL_URL } from "../../../config/const";
import { withRouter } from "react-router-dom";
import { inject, observer } from "mobx-react";
import { storage } from "../../../mobx/store";

// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/free-mode";
import "swiper/css/pagination";

// import required modules
import { FreeMode, Pagination } from "swiper";

const Home = ({ history, rootStore }) => {

  const context = useContext(PageSettings);

  const me = rootStore.getMe;

  // 선택된 지역과 카테고리
  const [area, setArea] = useState(0);
  const [category, setCategory] = useState(0);

  // 이벤트리스트, 추천시설, 시설리스트, 시설더보기버튼 현시여부, 공지리스트
  const [eventList, setEventList] = useState([]);
  const [recommendFacility, setRecommendFacility] = useState([]);
  const [facilityList, setFacilityList] = useState([]);
  const [showMoreBtn, toggleMoreBtn] = useState(false);
  const [noticeList, setNoticeList] = useState([]);
  const titleList = ['오늘은 헬스, 내일은 필라테스를 ', '오늘은 마사지, 내일은 요가를 '];
  const [title, setTitle] = useState('');

  useEffect(() => {
    const titleIdx = (Math.random() * 100).toFixed() % 2;
    setTitle(titleList[titleIdx]);

    getEventList();
    getNoticeList();
  }, []);

  useEffect(() => {
    getFacilityList(true);
  }, [rootStore.getLatitude, rootStore.getLongitude]);

  const getNoticeList = () => {
    // 공지리스트얻기
    context.get(
      'support/notice',
      {
        column: '',
        keyword: '',
        type: '',
        offset: 0,
        limit: 3
      },
      response => {
        setNoticeList(response.list);
      }
    );
  }

  const getEventList = () => {
    // 이벤트리스트얻기
    context.get(
      'support/notice',
      {
        column: '',
        keyword: '',
        type: 'E',
        offset: 0,
        limit: 5
      },
      response => {
        setEventList(response.list);
      }
    );
  }

  const getFacilityList = (refresh = false) => {
    if (!refresh && !showMoreBtn) {
      return;
    }

    // 시설리스트얻기
    context.get(
      'facility/list',
      {
        area,
        latitude: rootStore.getLatitude,
        longitude: rootStore.getLongitude,
        category,
        keyword: '',
        offset: refresh ? 0 : facilityList.length,
        limit: 6
      },
      response => {
        if (refresh) {
          setRecommendFacility(response.recommend);
        }
        setFacilityList(prev => refresh ? response.list : prev.concat(response.list));
        toggleMoreBtn(response.loadMore);
      }
    );
  }

  const onRegisterMembership = () => {
    if (!rootStore.isSignedIn) {
      context.showConfirm(
        strings.my_page.go_to_login,
        '',
        strings.signup.confirm,
        strings.my_page.cancel,
        response => {
          if (response == true) {
            history.push('/intro');
          }
        }
      );
      return;
    }

    history.push('/membership/level');
  }

  const onNoticeMore = () => {
    storage.removeNoticeEnv();
    history.push('/notice');
  }

  const onLink = (link) => {
    if (window.ReactNativeWebView) {
      let obj = {
        func: 'openUrl',
        params: {
          url: link
        }
      };

      let message = JSON.stringify(obj);
      window.ReactNativeWebView.postMessage(message);
    } else {
      window.open(link, "_blank", "noreferrer");
    }
  }

  const onClickEvent = (event) => {
    // 이벤트 상세 페이지로 이동
    history.push(`/notice/${event.id}`)
  }

  return (
    <Container className='home'>
      <div className='banner-wrapper'>
        {
          eventList.length > 0 &&
            <Swiper
              initialSlide={0}
              slidesPerView={1}
              spaceBetween={0}
              loop={true}
              // loopFillGroupWithBlank={true}
              pagination={{
                clickable: true,
              }}
              modules={[FreeMode, Pagination]}
              className="recommend">
              {
                eventList.map((event, idx) => (
                  <SwiperSlide key={idx}>
                    {/* <div className='item'> */}
                      <img src={event.file.getImages()} alt='photo' onClick={() => onClickEvent(event)} />
                    {/* </div> */}
                  </SwiperSlide>
                ))
              }
            </Swiper>
        }
        {
          (!rootStore.isSignedIn || (rootStore.isSignedIn && me?.level == null)) &&
          <button className='btn-register' onClick={onRegisterMembership}>{strings.home.register_membership}</button>
        }
      </div>
      {/* <h2 className='title'>{strings.home.check_facilities_around}</h2>
      <div className='divider' /> */}
      <div className='guide'>
        {title} <span className='samliphopang' style={{ fontSize: 20, color: '#ff3a4a' }}>{`"합티"`}</span> {' 하나로'}
      </div>

      <div className='facility'>
        <div className='header'>
          {'내 주변 시설'}
          {/* <select className='area' value={area} onChange={e => setArea(e.target.value)}>
            {
              areaList.map((it, idx) => (
                <option key={idx} value={it.id}>{it.name}</option>
              ))
            }
          </select>
          <select className='category' value={category} onChange={e => setCategory(e.target.value)}>
            {
              categoryList.map((it, idx) => (
                <option key={idx} value={it.id}>{it.name}</option>
              ))
            }
          </select> */}
        </div>
        <div className='body'>
          <div className='list'>
            {
              facilityList.map((it, idx) => (
                <button key={idx} className='item' onClick={() => history.push(`/facility/${it.id}`)}>
                  <img src={it.photo?.getImages()} alt='image' />
                  <div>{it.name}</div>
                  <div>{it.address}</div>
                </button>
              ))
            }
          </div>
        </div>
        {/* {
          showMoreBtn &&
          <button className='btn-more' onClick={() => getFacilityList(false)}>{strings.home.more}</button>
        } */}
      </div>

      <div className='facility' style={{ marginTop: 30 }}>
        <div className='header'>
          {'이런 시설은 어떠세요?'}
        </div>
        <div className='body'>
          <div className='list'>
            {
              recommendFacility.map((it, idx) => (
                <button key={idx} className='item' onClick={() => history.push(`/facility/${it.id}`)}>
                  <img src={it.photo?.getImages()} alt='image' />
                  <div>{it.name}</div>
                  <div>{it.address}</div>
                </button>
              ))
            }
          </div>
        </div>
        {/* {
          showMoreBtn &&
          <button className='btn-more' onClick={() => getFacilityList(false)}>{strings.home.more}</button>
        } */}
      </div>

      <div className='notice'>
        <div className='header'>
          <label className='title'>{strings.home.notice_event}</label>
          <button className='btn-more' onClick={onNoticeMore}>{strings.home.more}</button>
        </div>
        <div className='body'>
          {
            noticeList.map((it, idx) => (
              <button key={idx} className='item' onClick={() => history.push(`/notice/${it.id}`)}>
                ・{it.title}
              </button>
            ))
          }
        </div>
      </div>

      <div className='cscenter'>
        <div className='opening'>
          <label className='title'>{'고객센터'}</label>
          <label className='time'>{'평일 10:00 ~ 16:00 / 점심 12:30 ~ 13:30'}</label>
          <label className='time'>{'토, 일, 공휴일 휴무'}</label>
        </div>
        <button className='kakao-inquiry' onClick={() => { onLink(KAKAO_CHANNEL_URL) }}>
          <img src='assets/images/icon_kakao.png' />
          <label>{'카카오톡'}</label>
        </button>
      </div>
    </Container>
  );
}

export default withRouter(inject('rootStore')(observer(Home)));
