import React, { useContext, useEffect, useRef, useState } from 'react';
import Container from "../../../components/control/container";
import '../../../scss/pages/nearby.scss';
import strings from "../../../lang/strings";
import { withRouter } from "react-router-dom";
import { PageSettings } from "../../../config/page-settings";

// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/free-mode";
import "swiper/css/pagination";

// import required modules
import { FreeMode, Pagination } from "swiper";
import { rootStore } from '../../../mobx/store';

import Sheet from "react-modal-sheet";
// import { storage } from '../../../mobx/store';
// import { Map, MapMarker } from "react-kakao-maps-sdk";
// import { DEFAULT_POSITION } from '../../../config/const';

const NearBy = ({ history }) => {

  const context = useContext(PageSettings);

  // const { kakao } = window;
  // const geocoder = new kakao.maps.services.Geocoder();

  // 지역리스트
  const areaList = [{
    id: 0,
    name: '지역'
  }].concat(context.getSettingList('area').map(it => {
    return {
      id: it.id,
      name: it.name
    };
  }));

  // 카테고리리스트
  const categoryList = [{
    id: 0,
    name: strings.home.category
  }].concat(context.getSettingList('category'));

  // 선택된 지역과 카테고리, 키워드
  // const [address, setAddress] = useState('지역');
  const [area, setArea] = useState(0);
  const [category, setCategory] = useState(0);
  const [keyword, setKeyword] = useState('');
  // const [latitude, setLatitude] = useState('');
  // const [longitude, setLongitude] = useState('');
  const [isFilterOpen, setFilterOpen] = useState(false);
  const [selectedCategoryIdx, setSelectedCategoryIdx] = useState(0);

  // 시설리스트
  const [recommendFacility, setRecommendFacility] = useState([]);
  const [facilityList, setFacilityList] = useState([]);
  const loadingEnd = useRef(false);

  const areaRef = useRef(0);
  const categoryRef = useRef(0);
  const keywordRef = useRef('');
  const offsetRef = useRef(0);

  // const [isAreaSelectOpen, setAreaSelectOpen] = useState(false);
  // const [isMapOpen, setMapOpen] = useState(false);
  // const [mapCenter, setMapCenter] = useState({
  //   lat: DEFAULT_POSITION.LAT, lng: DEFAULT_POSITION.LNG
  // })

  useEffect(() => {
    areaRef.current = area;
    categoryRef.current = category;
    keywordRef.current = keyword;
    offsetRef.current = facilityList.length;
  }, [area, category, keyword, facilityList]);

  useEffect(() => {
    document.body.onscroll = () => {
      const reachEnd = window.scrollY + window.innerHeight >= document.body.scrollHeight;
      if (reachEnd && offsetRef.current > 0) {
        getFacilityList();
      }
    };

    // const messageListener = document.addEventListener('message', (nativeEvent) => {
    //   const data = JSON.parse(nativeEvent?.data);
    //   if (data.type == 'currentLocation') {
    //     console.log('nearby.js ====> ', data);
    //     // setLatitude(data.latitude);
    //     // setLongitude(data.longitude);
    //     setAddressFromCoord(data.latitude, data.longitude);
    //   }
    // });

    // // getCurrentPosition();
    // const selectedPosition = storage.getSelectedPosition();
    // if (selectedPosition != null) {
    //   setLatitude(selectedPosition?.lat);
    //   setLongitude(selectedPosition?.lng);
    //   setMapCenter({ lat: selectedPosition?.lat, lng: selectedPosition?.lng });
    //   setAddress(selectedPosition?.address)
    // }

    return () => {
      document.body.onscroll = null;
    }
  }, []);

  useEffect(() => {
    if (rootStore.getLatitude != '' && rootStore.getLongitude != '') {
      getFacilityList(true);
      getRecommendFacilityList();
    }
  }, [area, category, keyword, rootStore.getLatitude, rootStore.getLongitude]);

  // const getCurrentPosition = () => {
  //   if (window.ReactNativeWebView) {
  //     let obj = {
  //       func: 'onCurrentLocation',
  //       params: {}
  //     };

  //     let message = JSON.stringify(obj);
  //     window.ReactNativeWebView.postMessage(message);
  //   }
  // }

  const getFacilityList = (refresh = false) => {
    if (!refresh && loadingEnd.current) {
      return;
    }

    // 시설리스트얻기
    context.get(
      'facility/nearby',
      {
        area: areaList[areaRef.current].id,
        category: categoryList[categoryRef.current].id,
        keyword: keywordRef.current,
        latitude: rootStore.getLatitude,
        longitude: rootStore.getLongitude,
        offset: refresh ? 0 : offsetRef.current,
        limit: 20
      },
      response => {
        setFacilityList(prev => refresh ? response.list : prev.concat(response.list));
        loadingEnd.current = !response.loadMore;
      }
    );
  };

  const getRecommendFacilityList = () => {

    // 추천시설리스트얻기
    context.get(
      'facility/list',
      {
        area: areaRef.current,
        category: categoryRef.current,
        keyword: '',
        latitude: rootStore.getLatitude,
        longitude: rootStore.getLongitude,
        offset: 0,
        limit: 1
      },
      response => {
        setRecommendFacility(response.recommend);
      }
    );
  }

  const correctDistance = (distance) => {
    if (distance == '') {
      return '0.0km';
    } else {
      const dist = parseFloat(distance);
      return dist.toFixed(1) + 'km'
    }
  }

  const handleFilter = () => {
    setFilterOpen(true);
  }

  const handleFilterApply = () => {
    setFilterOpen(false);
    setCategory(selectedCategoryIdx);
  }

  const onFilterChange = (value) => {
    setSelectedCategoryIdx(selectedCategoryIdx == value ? 0 : value);
  }

  // const setAddressFromCoord = (lat, lng) => {
  //   setLatitude(lat);
  //   setLongitude(lng);
  //   setMapCenter({ lat: lat, lng: lng });
  //   geocoder.coord2Address(lng, lat, (result, status) => {
  //     console.log(result);
  //     if (status == "OK" && result.length > 0) {
  //       // const address = result[0].address.address_name;
  //       const address = result[0].address.region_1depth_name + ' ' + result[0].address.region_2depth_name + ' ' + result[0].address.region_3depth_name;
  //       setAddress(address)
  //       storage.setSelectedPosition(address, lat, lng);
  //     }
  //   })
  // }

  return (
    <Container className='nearby'>
      {/* <h2 className='title'>{strings.home.check_facilities_around}</h2>
      <div className='divider' />
      <label className='guide'>{strings.home.guide.nl2br()}</label>
      <input className='keyword' type='text' value={keyword} onChange={e => setKeyword(e.target.value)} /> */}
      {/* <div className='top-address' onClick={() => setAreaSelectOpen(true)}>
        {address}
        <img src='assets/images/icon_arrow_down_line.png' style={{ width: 10, marginLeft: 5 }} />
      </div> */}

      <div className='facility'>
        <div className='header'>
          <div className='area'>
          </div>
          <div className='area' onClick={() => handleFilter()}>
            {categoryList[category].name}
            <img src='assets/images/icon_arrow_down_line.png' style={{ width: 10, marginLeft: 5 }} />
          </div>
        </div>
        {
          recommendFacility.length > 0 &&
          <Swiper
            initialSlide={0}
            slidesPerView={1}
            spaceBetween={0}
            // loop={true}
            // loopFillGroupWithBlank={true}
            pagination={{
              clickable: true,
            }}
            modules={[FreeMode, Pagination]}
            className="recommend-wrapper">
            {
              recommendFacility.map((it, idx) => {
                const categoryIds = it.category.split(',');
                return <SwiperSlide key={idx}>
                  <button key={idx} className='recommend-item' onClick={() => history.push(`/facility/${it.id}`)}>
                    <img className='item-image' src={it.photo.getImages()} alt='image' />
                    <div className='badge'>합티추천</div>
                    <div className='content'>
                      <div className='info'>
                        <label className='name'>{it.name}</label>
                        <div className='rate'>
                          <img src='/assets/images/icon_star_yellow.png' />
                          {Number(it.rate).toFixed(1)}
                          <span>{`(${Number(it.review_count).currencyFormat()})`}</span>
                        </div>
                      </div>
                      <div className='info' style={{ marginTop: 5 }}>
                        <label className='address'>{it.address + it.address_detail}</label>
                        <label className='level'>{it.level_name}</label>
                      </div>
                      <div className='info'>
                        <div className='category'>
                          {
                            categoryIds.map((id, _) => (
                              <div key={id}>{categoryList.filter(c => c.id == id)[0]?.name}</div>
                            ))
                          }
                        </div>
                        <label className='distance'>{correctDistance(it.distance ?? '')}</label>
                      </div>
                    </div>
                  </button>
                </SwiperSlide>
              }
              )
            }
          </Swiper>
        }
        <div className='body'>
          {
            facilityList.map((it, idx) => {
              const categoryIds = it.category.split(',');
              return <button key={idx} className='item' onClick={() => history.push(`/facility/${it.id}`)}>
                <div className='image'>
                  <img src={it.photo.getImages()} alt='image' />
                </div>
                <div className='content'>
                  <div className='info'>
                    <label className='name'>{it.name}</label>
                    <label className='distance'>{correctDistance(it.distance ?? '')}</label>
                  </div>
                  <div className='info'>
                    <label className='address'>{it.address}</label>
                    <label className='level'>{it.level_name}</label>
                  </div>

                  <div className='rate'>
                    <img src='/assets/images/icon_star_yellow.png' />
                    {Number(it.rate).toFixed(1)}
                    <span>{`(${Number(it.review_count).currencyFormat()})`}</span>
                  </div>
                  <div className='category'>
                    {
                      categoryIds.map((id, _) => (
                        <div key={id}>{categoryList.filter(c => c.id == id)[0]?.name}</div>
                      ))
                    }
                  </div>
                </div>
              </button>
            })
          }
        </div>
      </div>
      <Sheet
        isOpen={isFilterOpen}
        onClose={() => setFilterOpen(false)}
        snapPoints={[0.7]}>
        <Sheet.Container>
          <Sheet.Header>
            <div className='modal-sheet-header' style={{ borderBottom: '1px solid #eae9ee' }}>
              <div className='modal-sheet-header-tab' style={{ color: '#000000' }}>카테고리</div>
            </div>
          </Sheet.Header>
          <Sheet.Content>
            <div style={{ height: '100%', display: 'flex', flexDirection: 'column' }}>
              <div className='modal-sheet-filter-body' style={{ flex: 1 }}>
                <div className='category-filter'>
                  {
                    categoryList.map((it, idx) => {
                      return idx == 0 ? null : <label key={idx} className='checkbox'
                        onClick={() => onFilterChange(idx)}>
                        <img srcSet={selectedCategoryIdx == idx ?
                          './assets/images/icon_checkbox_checked.png 3x' :
                          './assets/images/icon_checkbox_unchecked.png 3x'} />
                        {it.name}
                      </label>
                    })
                  }
                </div>
              </div>
              <div className='modal-sheet-bottom'>
                <div className='cancel-button' onClick={() => setFilterOpen(false)}>{'취소'}</div>
                <div className='confirm-button' style={{ marginLeft: 10 }} onClick={handleFilterApply}>{'적용하기'}</div>
              </div>
            </div>
          </Sheet.Content>
        </Sheet.Container>
        <Sheet.Backdrop onClick={() => setFilterOpen(false)} />
      </Sheet>
      {/* <Sheet
        isOpen={isAreaSelectOpen}
        onClose={() => setAreaSelectOpen(false)}
        snapPoints={[150]}>
        <Sheet.Container>
          <Sheet.Header>
            <div className='modal-sheet-header'>
              <div className='left' onClick={() => setAreaSelectOpen(false)}>
                <img src='assets/images/icon_close.png' />
              </div>
              <label className='center'>내 위치 지정</label>
              <div className='right'>
              </div>
            </div>
          </Sheet.Header>
          <Sheet.Content>
            <>
              <div className='sheet-select-div' onClick={() => {
                setAreaSelectOpen(false)
                getCurrentPosition()
              }}>현재 위치로 설정</div>
              <div className='sheet-select-div' onClick={() => {
                setAreaSelectOpen(false)
                setMapOpen(true);
              }}>지도에서 위치 지정</div>
            </>
          </Sheet.Content>
        </Sheet.Container>
        <Sheet.Backdrop onClick={() => setAreaSelectOpen(false)} />
      </Sheet>
      <Sheet
        isOpen={isMapOpen}
        onClose={() => setMapOpen(false)}
        disableDrag>
        <Sheet.Container>
          <Sheet.Header>
            <div className='modal-sheet-header'>
              <div className='left' onClick={() => setMapOpen(false)}>
                <img src='assets/images/icon_close.png' />
              </div>
              <label className='center'>지도에서 위치 지정</label>
              <div className='right' onClick={() => setMapOpen(false)}>
                확인
              </div>
            </div>
          </Sheet.Header>
          <Sheet.Content>
            <>
              <Map
                center={mapCenter}
                style={{ width: "100%", height: "100%" }}
                onClick={(_t, mouseEvent) => {
                  setAddressFromCoord(mouseEvent.latLng.getLat(), mouseEvent.latLng.getLng());
                }}>
                <MapMarker // 마커를 생성합니다
                  position={{
                    // 마커가 표시될 위치입니다
                    lat: latitude,
                    lng: longitude,
                  }}
                />
              </Map>
            </>
          </Sheet.Content>
        </Sheet.Container>
        <Sheet.Backdrop onClick={() => setMapOpen(false)} />
      </Sheet> */}
    </Container>
  );
}

export default withRouter(NearBy);
