import React, { useContext } from 'react';

import '../../scss/components/side-menu.scss';
import strings from "../../lang/strings";
import { withRouter } from "react-router-dom";
import { inject, observer } from "mobx-react";
import { PageSettings } from "../../config/page-settings";
import { storage } from "../../mobx/store";

const SideMenu = ({ active, rootStore, onClose, history }) => {

  const context = useContext(PageSettings);
  const me = rootStore.getMe;

  const onLogout = () => {
    if (rootStore.isSignedIn) {
      context.showConfirm(
        strings.side_menu.log_out_confirm,
        '',
        strings.signup.confirm,
        strings.my_page.cancel,
        response => {
          if (response == true) {
            storage.setTab(0);
            storage.removeAutoLogin();
            rootStore.signOut();
            history.push('/intro');
          }
        }
      )
    } else {
      history.push('/intro');
    }
  };

  const onMenu = idx => {
    onClose?.();
    switch (idx) {
      case 0:
        if (rootStore.isSignedIn) {
          history.push('/ticket-log');
        } else {
          context.showConfirm(
            strings.my_page.go_to_login,
            '',
            strings.signup.confirm,
            strings.my_page.cancel,
            response => {
              if (response == true) {
                history.push('/login');
              }
            }
          )
        }
        break;
      case 1:
        history.push('/faq');
        break;
      case 2:
        storage.removeNoticeEnv();
        history.push('/notice');
        break;
      case 3:
        if (rootStore.isSignedIn) {
          history.push('/inquiry');
        } else {
          context.showConfirm(
            strings.my_page.go_to_login,
            '',
            strings.signup.confirm,
            strings.my_page.cancel,
            response => {
              if (response == true) {
                history.push('/login');
              }
            }
          )
        }
        break;
      default:
    }
  }

  return (
    <div className={'side-menu-wrapper' + (active ? ' active' : '')} onClick={onClose}>
      <div className='side-menu' onClick={e => e.stopPropagation()}>
        <div className='side-header'>
          <div className='profile-img'>
            {
              rootStore.isSignedIn ?
                <img src={me?.profile?.getFullUrl()} alt='profile' />
                :
                <img srcSet={'./assets/images/img_default_profile.png 3x'} alt='profile' />
            }
          </div>
          <div className='user-info'>
            <div>
              <label className='user-name'>{rootStore.isSignedIn ? me?.name : strings.home.login_first}</label>
              {
                rootStore.isSignedIn &&
                <div className='member-level'>
                  <label>{me?.level == null ? '준회원' : me?.level?.name}</label>
                </div>
              }
            </div>
            <button className='btn-logout' onClick={onLogout}>
              {rootStore.isSignedIn ? strings.side_menu.log_out : strings.side_menu.log_in}
            </button>
          </div>
        </div>
        <div className='side-content'>
          <button className='menu-item' onClick={() => onMenu(0)}>
            <div className='icon' />
            <label>{strings.side_menu.ticket_usage}</label>
            <img srcSet={'./assets/images/icon_arrow_right_line.png 3x'} alt='arrow' />
          </button>
          <button className='menu-item' onClick={() => onMenu(1)}>
            <div className='icon' />
            <label>{strings.side_menu.faq}</label>
            <img srcSet={'./assets/images/icon_arrow_right_line.png 3x'} alt='arrow' />
          </button>
          <button className='menu-item' onClick={() => onMenu(2)}>
            <div className='icon' />
            <label>{strings.side_menu.notice_event}</label>
            <img srcSet={'./assets/images/icon_arrow_right_line.png 3x'} alt='arrow' />
          </button>
          <button className='menu-item' onClick={() => onMenu(3)}>
            <div className='icon' />
            <label>{strings.side_menu.partnership_inquiry}</label>
            <img srcSet={'./assets/images/icon_arrow_right_line.png 3x'} alt='arrow' />
          </button>
        </div>
      </div>
    </div>
  );

};

export default withRouter(inject('rootStore')(observer(SideMenu)));
