import React, { useContext, useEffect, useState } from 'react';

import { PageSettings } from '../../../config/page-settings';
import Container from "../../../components/control/container";
import TopBar from "../../../components/control/top-bar";
import { Swiper, SwiperSlide } from "swiper/react";
import { FreeMode, Pagination } from "swiper";
import strings from "../../../lang/strings";
import { withRouter } from "react-router-dom";
import NfcOptionPopup from "../../popup/nfc-option";
import { rootStore } from '../../../mobx/store';

// Import Swiper styles
import "swiper/css";
import "swiper/css/free-mode";
import "swiper/css/pagination";

const Nfc = ({ id, history }) => {
  const context = useContext(PageSettings);

  const [info, setInfo] = useState(null);
  const [list, setList] = useState([]);
  const [selectedService, setSelectedService] = useState(0);
  const [showPopup, togglePopup] = useState(false);

  useEffect(() => {
    if (context.getOSType() == 'ios') {
      window.addEventListener('message', handleMessage);
    } else {
      document.addEventListener('message', handleMessage);
    }

    if (id) {
      getInfo();
    }

    return () => {
      if (context.getOSType() == 'ios') {
        window.removeEventListener('message', handleMessage);
      } else {
        document.removeEventListener('message', handleMessage);
      }
    };
  }, []);

  const handleMessage = (event) => {
    if (typeof event?.data != "object" && event?.data.isJSON()) {
      const data = JSON.parse(event?.data);
      if (data.type == 'payResult') {
        if (data.result == 'success') {
          onConfirm('card', selectedService)
        } else {
          context.showAlert(data.errMsg);
        }
      }
    }
  }

  const getInfo = () => {
    // 시설상세정보얻기
    context.get(
      `facility/detail/${id}`,
      {},
      response => {
        setInfo(response);
        setList(response.services);
      }
    );
  }

  const onConfirm = (pay_type, service_id) => {
    context.post(
      'facility/visit',
      {
        nfc: info?.nfc,
        pay_type,
        service_id
      },
      response => {
        context.showConfirm(
          '',
          strings.nfc.visit_confirmed,
          strings.signup.confirm,
          '',
          response => {
            history.goBack();
          }
        );
      }
    );
  }

  return (
    <Container className='facility-detail nfc'>
      <TopBar title={strings.signup.prev} />

      <div className='content'>
        <Swiper
          initialSlide={0}
          slidesPerView={1}
          spaceBetween={0}
          // loop={true}
          // loopFillGroupWithBlank={true}
          pagination={{
            clickable: true,
          }}
          modules={[FreeMode, Pagination]}
          className="banner">
          {
            info?.photo?.getImages(false).map((it, idx) => (
              <SwiperSlide key={idx}>
                <div className='item'>
                  <img src={it} alt='image' />
                </div>
              </SwiperSlide>
            ))
          }
        </Swiper>

        <div className='facility-info'>
          <div className='first'>
            <div className='icon heart'>
              <img srcSet={'../assets/images/icon_heart.png 3x'} alt='heart' />
            </div>
            <label className='name'>{info?.name}</label>
            <div className='stars'>
              {
                [...Array(5).keys()].map((it, idx) => (
                  <img key={idx} srcSet={'../assets/images/icon_star.png 3x'} alt='star' />
                ))
              }
            </div>
            {/*<label className='badge'>
              {context.getSettingList('area').find(it => it.id == info?.area)?.name}
            </label>*/}
            <label className='badge'>
              {context.getSettingList('level').find(it => it.id == info?.level)?.name}
            </label>
          </div>
          <div className='second'>
            <div className='icon'>
              <img srcSet={'../assets/images/icon_location.png 3x'} alt='location' />
            </div>
            <label>{info?.address || ''}</label>
          </div>
          <div className='second'>
            <div className='icon'>
              <img srcSet={'../assets/images/icon_phone.png 3x'} alt='location' />
            </div>
            <label>{info?.phone || ''}</label>
          </div>
        </div>

        <div className='list'>
          <label className='guide'>{strings.home.select_service_you_with.nl2br()}</label>
          <div className='item-list'>
            {
              list.length > 0 &&
              <button className='item' onClick={() => onConfirm('', 0)} style={{ border: '1px solid #ff3a4a', borderRadius: 10, backgroundColor: 'white' }}>
                {'추가 서비스 이용하지 않음'}
              </button>
            }
            {
              list.map((it, idx) => (
                <button key={idx} className='item' onClick={() => togglePopup(true)} style={{ border: '1px solid #ff3a4a', borderRadius: 10, backgroundColor: 'white' }}>
                  {it.name}
                  <span>{strings.formatString(strings.home.add_required, it.price.currencyFormat())}</span>
                </button>
              ))
            }
          </div>
        </div>
      </div>

      {/* <div className='footer'>
        <button className='btn-bottom active' onClick={onConfirm}>
          {strings.signup.confirm}
        </button>
        <button className='btn-bottom' onClick={() => history.goBack()}>
          {strings.my_page.cancel}
        </button>
      </div> */}

      <NfcOptionPopup
        active={showPopup}
        data={list}
        onClose={() => togglePopup(false)}
        onConfirm={(idx, payment) => {
          togglePopup(false);
          // 결제처리
          const name = list[idx].name;
          const price = list[idx].price;

          setSelectedService(list[idx].id);

          if (payment == 1) { // 현장결제
            onConfirm('direct', list[idx].id);
          } else {  // 카드결제
            let obj = {
              func: 'onPayment',
              params: {
                pg: 'card',
                amount: price,
                userName: rootStore.getMe?.name,
                userEmail: rootStore.getMe?.email,
                userPhone: rootStore.getMe?.phone || '01012345678',
                payName: name + ' 서비스 결제'
              }
            };

            let message = JSON.stringify(obj);
            window.ReactNativeWebView.postMessage(message);
          }
        }} />
    </Container>
  );
}

export default withRouter(Nfc);
