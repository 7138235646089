import { inject } from 'mobx-react';
import React, { useContext, useEffect, useState } from 'react';
import { withRouter } from 'react-router-dom';

import { PageSettings } from '../../config/page-settings';
import Container from "../../components/control/container";

import '../../scss/pages/login.scss';
import TopBar from "../../components/control/top-bar";
import strings from "../../lang/strings";
import { LOGIN_TYPE } from "../../config/const";
import { storage } from "../../mobx/store";

const Login = ({ rootStore, history }) => {

  const context = useContext(PageSettings);

  const [email, setEmail] = useState('');
  const [pwd, setPwd] = useState('');
  const [deviceId, setDeviceId] = useState('');

  useEffect(() => {
    let messageListener;
    if (context.getOSType() == 'ios') {
      messageListener = window.addEventListener('message', handleMessage);
    } else {
      messageListener = document.addEventListener('message', handleMessage);
    }

    if (window.ReactNativeWebView) {
      getDeviceId();
    }

    return messageListener;
  }, []);

  const handleMessage = (event) => {
    if (typeof event?.data != "object" && event?.data.isJSON()) {
      const data = JSON.parse(event?.data);
      if (data.type == 'setDeviceId') {
        setDeviceId(data.deviceId);
        context.handleSetDeviceId(data.deviceId);
      }
    }
  }

  const getDeviceId = () => {
    let obj = {
      func: 'getDeviceId',
      params: {}
    };

    let message = JSON.stringify(obj);
    window.ReactNativeWebView.postMessage(message);
  }

  const onLogin = () => {
    const device_id = context.getDeviceId();
    const os = context.getOSType();

    // if (os != 'web' && (device_id == '')) {
    //   context.showAlert('USIM이 삽입된 폰에서만 로그인 가능합니다.');
    //   return;
    // }
    if (email == '') {
      context.showAlert(strings.login.email_placeholder);
      return;
    }
    if (pwd == '') {
      context.showAlert(strings.login.password_placeholder);
      return;
    }

    // 로그인처리진행
    context.post(
      'auth/login',
      {
        login_type: LOGIN_TYPE.EMAIL,
        device_id: deviceId,
        email,
        pwd,
        os
      },
      response => {
        rootStore.signIn(response.token);
        storage.setAutoLogin(LOGIN_TYPE.EMAIL, email, pwd);
        history.push('/main');
      }
    );
  }

  const onFindID = (type) => {
    // 아이디찾기화면으로 이동
    history.push(`/find/${type}`);
  }

  const onSignup = () => {
    // 회원가입화면으로 이동
    storage.removeSignupUser();
    storage.saveSignupUser({
      login_type: LOGIN_TYPE.EMAIL
    });
    history.push('/signup');
  }

  const onChangePhone = () => {
    // 휴대전화 변경요청 페이지로 이동
    history.push('/change-phone');
  }

  return (
    <Container className='login'>
      <TopBar title={strings.intro.login} />

      <div className='content'>
        <div className='logo-img'>
          <img src='assets/images/logo1.png' />
        </div>

        <div className='input-group'>
          <div className='input-wrapper'>
            <div className='input-icon'>
              <img srcSet={'./assets/images/icon_email.png 3x'} alt='email' />
            </div>
            <div className='input'>
              <input
                type='email'
                value={email}
                placeholder={strings.login.email_placeholder}
                onChange={e => setEmail(e.target.value)} />
            </div>
          </div>
          <div className='input-wrapper'>
            <div className='input-icon'>
              <img srcSet={'./assets/images/icon_password.png 3x'} alt='lock' />
            </div>
            <div className='input'>
              <input
                type='password'
                value={pwd}
                placeholder={strings.login.password_placeholder}
                onChange={e => setPwd(e.target.value)} />
            </div>
          </div>
        </div>

        <button className='btn btn-login' onClick={onLogin}>{strings.intro.login}</button>
        <div className='btn-group'>
          <button className='btn btn-find' onClick={() => onFindID('id')}>{strings.login.find_id}</button>
          <button className='btn btn-find' style={{marginLeft: 20}} onClick={() => onFindID('pwd')}>{strings.login.find_pwd}</button>
        </div>
        <button className='btn btn-signup' onClick={onSignup}>{strings.login.sign_up}</button>
        {
          context.getOSType() != 'web' &&
          <label className='change-phone'>
            {strings.login.change_your_phone}
            <span onClick={onChangePhone}> {strings.login.change_phone}</span>
          </label>
        }
      </div>
    </Container>
  );
}

export default withRouter(inject('rootStore')(Login));
