import React, { useEffect, useState } from 'react';

import strings from "../../../lang/strings";
import { storage } from "../../../mobx/store";
import AreaList from "../../../components/control/area-list";

const SignupArea = () => {

  const [user, setUser] = useState(storage.getSignupUser());

  useEffect(() => {
    storage.saveSignupUser(user);
  }, [user]);

  const onChange = (prop, value) => {
    if (typeof prop == "object") {
      setUser(prev => {
        return {
          ...prev,
          ...prop
        }
      });
      return;
    }
    setUser(prev => {
      return {
        ...prev,
        [prop]: value
      }
    });
  }

  const onSelect = id => {
    const areaList = user?.area ?? [];
    onChange(
      'area',
      areaList.includes(id) ? areaList.filter(it => it != id) : areaList.concat(id)
    );
  }

  return (
    <div className='gender-age'>
      <h3 className='caption'>{strings.signup.select_area.nl2br()}</h3>
      <label className='guide'>{strings.signup.multiple_possible}</label>
      <AreaList selectData={user?.area ?? []} onChange={onSelect} />
    </div>
  );
}

export default SignupArea;
