import React, { useContext, useEffect, useState } from 'react';
import { PageSettings } from "../../config/page-settings";
import strings from "../../lang/strings";


const NfcOptionPopup = ({ active, data, onClose, onConfirm }) => {

  const context = useContext(PageSettings);

  const [option, setOption] = useState(0);
  const [payment, setPayment] = useState(1);
  const [price, setPrice] = useState(0);

  const [paymentList, setPaymentList] = useState([
    { id: 1, title: '현장결제' },
    { id: 2, title: '카드결제' },
  ]);

  useEffect(() => {
    if (data.length > 0) {
      setPrice(data[0].price);
    }
  }, [data])

  return (
    <div className={'nfc-option' + (active ? ' active' : '')} onClick={onClose}>
      <div className='content' onClick={e => e.stopPropagation()}>
        {/*<img srcSet={'../assets/images/icon_arrow_down.png 3x'} />*/}
        <label className='caption' style={{ marginTop: 37 }}>{strings.nfc.select_option}</label>
        <select value={option} onChange={e => {
          setOption(e.target.value);
          setPrice(data[e.target.value].price)
        }}>
          {
            data.map((it, idx) => (
              <option key={idx} value={idx}>{it.name}</option>
            ))
          }
        </select>
        <label className='caption'>{strings.nfc.payment_method}</label>
        <select value={payment} onChange={e => setPayment(e.target.value)}>
          {
            paymentList.map((it, idx) => (
              <option key={idx} value={it.id}>{it.title}</option>
            ))
          }
        </select>
        <label className='sum'>{strings.formatString(strings.nfc.additional_payment, `${price}`.currencyFormat())}</label>
        <div className='footer'>
          <button className='btn-bottom active' onClick={() => {onConfirm(option, payment)}}>
            {strings.signup.confirm}
          </button>
          <button className='btn-bottom' onClick={onClose}>
            {strings.my_page.cancel}
          </button>
        </div>
      </div>
    </div>
  );

};

export default NfcOptionPopup;
