import React, { useContext, useState } from 'react';
import { withRouter } from 'react-router-dom';

import { PageSettings } from '../../config/page-settings';
import Container from "../../components/control/container";
import strings from "../../lang/strings";

import '../../scss/pages/find.scss';
import TopBar from "../../components/control/top-bar";


const ChangePhone = ({ history }) => {

  const context = useContext(PageSettings);

  const [phone, setPhone] = useState('');
  const [reason, setReason] = useState('');

  const onRequest = () => {
    if (phone == '') {
      context.showAlert('전화번호를 입력하세요.');
      return;
    }

    if (reason == '') {
      context.showAlert('요청사유를 입력하세요.');
      return;
    }

    context.post(
      'auth/changePhone',
      { 
        phone,
        reason,
        device_id: context.getDeviceId() 
      },
      response => {
        // console.log(response.email);
        context.showAlert('변경요청이 완료되었습니다.\n관리자 확인 후 처리됩니다.', () => history.goBack())
      }
    );
  }

  return (
    <Container className='find'>
      <TopBar title={'휴대전화 변경 요청'} />
      <div className='content'>
        <label className='caption'>{'전화번호'}</label>
        <div className='input-wrapper'>
          <input type={'tel'} value={phone}
            placeholder={'전화번호를 입력하세요.'}
            onChange={e => setPhone(e.target.value)} />
        </div>
        <label className='caption'>{'요청사유'}</label>
        <div className='input-wrapper' style={{height: 100}}>
          <textarea value={reason}
            placeholder={'요청사유를 입력하세요.'}
            onChange={e => setReason(e.target.value)} />
        </div>
      </div>
      <button className='btn-confirm' onClick={onRequest}>{'요청하기'}</button>
    </Container>
  );
}

export default withRouter(ChangePhone);
