import React, { useContext, useEffect, useState } from 'react';

import { PageSettings } from '../../../config/page-settings';
import Container from "../../../components/control/container";
import TopBar from "../../../components/control/top-bar";
import strings from "../../../lang/strings";
import { IS_UI_MODE } from "../../../config/const";
import { withRouter } from "react-router-dom";
import Pagination from "../../../components/control/pagination";
import { storage } from "../../../mobx/store";

const Notice = ({ history }) => {

  const context = useContext(PageSettings);
  const noticeEnv = storage.getNoticeEnv();

  const [column, setColumn] = useState(noticeEnv.column);
  const [keyword, setKeyword] = useState(noticeEnv.keyword);

  const [totalCount, setTotalCount] = useState(0);
  const [activePage, setActivePage] = useState(noticeEnv.activePage);
  const [list, setList] = useState([]);

  const LIMIT_COUNT = 20;

  useEffect(() => {
    getList();
  }, [activePage]);

  const onSearch = () => {
    if (activePage != 1) {
      setActivePage(1);
    } else {
      getList();
    }
  }

  const getList = () => {
    if (IS_UI_MODE) {
      getDummyData();
      return;
    }

    context.get(
      'support/notice',
      {
        column,
        keyword,
        type: '',
        offset: (activePage - 1) * LIMIT_COUNT,
        limit: LIMIT_COUNT
      },
      response => {
        storage.saveNoticeEnv(column, keyword, activePage);
        setList(response.list);
        setTotalCount(response.totalCount);
      }
    );
  }

  const getDummyData = () => {
    setList([...Array(10).keys()].map(it => {
      return {
        id: it,
        type: ['N', 'E'][it % 2],
        title: 'Its open. Hello!',
        content: 'Hi, this is content',
        file: '',
        create_date: ''
      }
    }));
  }

  return (
    <Container className='notice'>
      <TopBar center={true} title={strings.side_menu.notice_event} />
      <div className='content'>
        <div className='search'>
          <select className='type' value={column} onChange={e => setColumn(e.target.value)}>
            <option value='title'>{strings.side_menu.title}</option>
            <option value='content'>{strings.side_menu.content}</option>
          </select>
          <div className='keyword-wrapper'>
            <input className='keyword' value={keyword} onChange={e => setKeyword(e.target.value)} />
            <button className='btn-search' onClick={onSearch}>{strings.side_menu.search}</button>
          </div>
        </div>
        <div className='table-wrapper'>
          <label className='total-count'>{strings.formatString(strings.side_menu.total, totalCount)}</label>
          <div className='table'>
            <div className='thead'>
              <label className='no'>{strings.side_menu.no}</label>
              <label className='title'>{strings.side_menu.title}</label>
            </div>
            <div className='tbody'>
              {
                list.map((it, idx) => (
                  <div key={idx} className='tr' onClick={() => history.push(`/notice/${it.id}`)}>
                    <div className='no'>
                      {
                        it.type == 'N' ? <div className='notice-badge'>{strings.side_menu.notice}</div> : it.id
                      }
                    </div>
                    <div className='title'>{it.title}</div>
                  </div>
                ))
              }
            </div>
          </div>
          <Pagination
            activePage={activePage}
            pageCount={Math.ceil(totalCount / LIMIT_COUNT)}
            onPage={page => {
              if (page == activePage) {
                return;
              }
              setActivePage(page);
            }} />
        </div>
      </div>
    </Container>
  );
}

export default withRouter(Notice);
