import React, { useContext, useEffect, useState } from 'react';

import { PageSettings } from '../../../config/page-settings';
import Container from "../../../components/control/container";
import '../../../scss/pages/gift.scss';
import strings from "../../../lang/strings";
import { FORMAT } from "../../../config/const";
import GiftSelectUserPopup from "../../popup/select-user";
import Pagination from "../../../components/control/pagination";
import { inject, observer } from "mobx-react";
import dayjs from "dayjs";
import GiftAlertPopup from '../../popup/gift-alert';

const Gift = ({ rootStore }) => {

  const context = useContext(PageSettings);

  const [me, setMe] = useState(null);
  const [totalCount, setTotalCount] = useState(0);
  const [list, setList] = useState([]);
  const [activePage, setActivePage] = useState(1);
  const [showPopup, togglePopup] = useState(false);
  const [showAlert, toggleAlertPopup] = useState(false);

  const LIMIT_COUNT = 20;

  useEffect(() => {
    context.getMyInfo();

    if (context.getOSType() == 'ios') {
      window.addEventListener('message', handleMessage);
    } else {
      document.addEventListener('message', handleMessage);
    }

    return () => {
      if (context.getOSType() == 'ios') {
        window.removeEventListener('message', () => { });
      } else {
        document.removeEventListener('message', () => { });
      }
    };
  }, []);

  useEffect(() => {
    getList();
  }, [activePage]);

  useEffect(() => {
    setMe(rootStore.getMe);
  }, [rootStore.getMe]);

  const handleMessage = (event) => {
    if (typeof event?.data != "object" && event?.data.isJSON()) {
      const data = JSON.parse(event?.data);
      if (data.type == 'giftResult') {
        if (data.result == true) {
          createGiftLog();
        } else {
          context.showAlert('선물하기가 실패하였습니다.');
        }
      }
    }
  }

  const getList = () => {
    // 선물로그리스트를 블러 온다.
    context.get(
      'gift/list',
      {
        offset: (activePage - 1) * LIMIT_COUNT,
        limit: LIMIT_COUNT
      },
      response => {
        setList(response.list);
        setTotalCount(response.totalCount);
      }
    );
  };

  const onCreateLink = () => {
    const remain_count = me.ticket.total_count - me.ticket.use_count;
    if (remain_count < 1) {
      context.showAlert('선물가능한 티켓이 존재하지 않습니다.');
      return;
    }

    // 오늘 선물한적이 있으면 더 이상 선물 불가
    const currentDate = dayjs(Date.now()).format(FORMAT.DATE);
    const lastGiftDate = dayjs(me?.last_gift_date).format(FORMAT.DATE);
    if (currentDate == lastGiftDate) {
      context.showAlert('선물은 하루에 한번만 가능합니다.');
      return;
    }

    toggleAlertPopup(true);

    // context.showConfirm(
    //   '티켓을 선물하시겠습니까?\n',
    //   '- 확인을 누를경우 자동으로 티켓이 차감됩니다.\n (무조건 선물을 보내셔야 합니다.)\n- 확인을 누르면, 선물을 보내기 위해 카카오톡 페이지로 이동합니다.\n(*선물 한 당일에는 사용할수 없으니, 이점 유의해 주세요.)',
    //   '확인',
    //   '취소',
    //   response => {
    //     if (response == true) {
    //       sendGiftLink();
    //     }
    //   }
    // );
  }

  const sendGiftLink = () => {
    const currentDate = dayjs(Date.now()).format(FORMAT.DATE)
    if (window.ReactNativeWebView) {
      let obj = {
        func: 'sendGift',
        params: {
          giftSenderId: me?.id,
          giftSenderName: me?.name, // '몬스터',
          giftLevel: me?.level.name, // 'Premium',
          giftDate: currentDate
        }
      };

      let message = JSON.stringify(obj);
      window.ReactNativeWebView.postMessage(message);
    }
  }

  const createGiftLog = () => {
    context.post(
      'gift/send',
      {},
      (response) => {
        context.getMyInfo();
      }
    );
  }

  return (
    <Container className='gift'>
      <h3 className='ticket-label'>{strings.t_gift.giftable_ticket}</h3>
      <div className='ticket-info'>
        <label className='ticket-count'>
          {strings.formatString(strings.t_gift.gift_count, (me?.ticket.total_count || 0) - (me?.ticket.use_count || 0))}
        </label>
        <label className='month'>
          {strings.formatString(strings.side_menu.remain_info, dayjs().format('M'))}
        </label>
      </div>
      <button className='btn-create-link' onClick={onCreateLink}>{strings.t_gift.create_link}</button>

      <label className='gift-policy'>{strings.t_gift.gift_policy}</label>
      <div className='policy-content'>
        {strings.formatString(strings.t_gift.guide, "회원").nl2br()}
      </div>

      <label className='gift-policy'>{strings.t_gift.gift_usage_history}</label>
      <table className='gift-table'>
        <thead>
          <tr>
            <th style={{ width: '30%' }}>{strings.t_gift.id}</th>
            <th>{strings.t_gift.facility_used}</th>
            <th style={{ width: '30%' }}>{strings.t_gift.date_of_use}</th>
          </tr>
        </thead>
        <tbody>
          {
            list.map((it, idx) => (
              <tr key={idx}>
                <td>{it.user.name}</td>
                <td><label>{it.facility.name || '-'}</label></td>
                <td>{dayjs(it.create_date).format(FORMAT.DATE)}</td>
              </tr>
            ))
          }
        </tbody>
      </table>
      <Pagination
        activePage={activePage}
        pageCount={Math.ceil(totalCount / LIMIT_COUNT)}
        onPage={page => {
          if (page == activePage) {
            return;
          }
          setActivePage(page);
        }} />
      <GiftSelectUserPopup
        active={showPopup}
        onClose={(refresh) => {
          togglePopup(false);
          if (refresh === true) {
            context.showAlert(strings.t_gift.gift_success);
            context.getMyInfo();
            getList();
          }
        }} />
      <GiftAlertPopup
        show={showAlert}
        onModalClose={(result) => {
          toggleAlertPopup(false);
          if (result == true) {
            sendGiftLink();
          }
        }}
      />
    </Container>
  );
}

export default inject('rootStore')(observer(Gift));
