import React, { useContext, useEffect, useState } from 'react';

import { PageSettings } from '../../../config/page-settings';
import Container from "../../../components/control/container";
import TopBar from "../../../components/control/top-bar";
import { withRouter } from "react-router-dom";
import strings from "../../../lang/strings";
import { inject } from "mobx-react";

const TrainerDetail = ({ id, history, rootStore }) => {
  const context = useContext(PageSettings);

  const [info, setInfo] = useState(null);
  const [ptList, setPTList] = useState([]);

  useEffect(() => {
    if (id) {
      getInfo();
    }
  }, []);

  const getInfo = () => {
    // 강사상세정보얻기
    context.get(
      `facility/trainer/detail/${id}`,
      {},
      response => {
        setInfo(response);
        const ptInfo = response.pt_info;
        if (ptInfo != '') {
          const list = JSON.parse(ptInfo);
          setPTList(list);
        }
      }
    );
  }

  const onReserve = () => {
    if (!rootStore.isSignedIn) {
      context.showConfirm(
        strings.my_page.go_to_login,
        '',
        strings.signup.confirm,
        strings.my_page.cancel,
        response => {
          if (response == true) {
            history.push('/intro');
          }
        }
      );
      return;
    }
    history.push(`/reservation/${id}`);
  };

  const openUrl = (url) => {
    if (window.ReactNativeWebView) {
      let obj = {
        func: 'openUrl',
        params: {
          url: url
        }
      };

      let message = JSON.stringify(obj);
      window.ReactNativeWebView.postMessage(message);
    } else {
      window.open(url, "_blank", "noreferrer");
    }
  }

  const onCall = () => {
    const phoneNumber = info?.phone || '';
    if (phoneNumber != '') {
      if (window.ReactNativeWebView) {
        let obj = {
          func: 'callPhone',
          params: {
            phone: phoneNumber
          }
        };

        let message = JSON.stringify(obj);
        window.ReactNativeWebView.postMessage(message);
      }
    }
  }

  return (
    <Container className='trainer-detail'>
      <div className='top-bar'>
        <button className='btn-back' onClick={() => history.goBack()}>
          <img srcSet={'../../assets/images/icon_back.png 3x'} alt='back' />
        </button>
      </div>

      <div className='content'>
        <div className='top-bg'>
          <div className='bg-div'></div>
          <img className='bg-img' src={info?.image.getImages()} alt='강사이미지' />
        </div>
        <img className='profile-img' src={info?.image.getImages()} alt='강사이미지' />
        <div className='trainer-info'>
          <label className='name'>{info?.name}</label>
          <label className='facility'>{info?.facility_name}</label>
          <label className='address'>{info?.address}</label>
          {
            info?.experience == 1 &&
            <div className='exp-class'>
              🔹체험수업
            </div>
          }
          <div className='trainer-intro' dangerouslySetInnerHTML={{ __html: info?.intro || '' }} />
          {
            ptList.length > 0 &&
            <div className='pt-list'>
              {
                ptList.map((pt, idx) => (
                  <div key={idx} className='pt-info'>
                    <div className='pt-name'>{pt.name}</div>
                    <div className='pt-intro'>{pt.intro}</div>
                  </div>
                ))
              }
            </div>
          }
        </div>

        <button className='btn-reserve' onClick={onReserve}>{strings.home.make_reservation}</button>
      </div>

    </Container>
  );
}

export default withRouter(inject('rootStore')(TrainerDetail));
