// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("../../public/assets/fonts/SDSamliphopangcheTTFBasic.ttf", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_1___ = new URL("../../public/assets/fonts/NanumBarunGothicLight.woff2", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_2___ = new URL("../../public/assets/fonts/NanumBarunGothic.woff2", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_3___ = new URL("../../public/assets/fonts/NanumBarunGothicBold.woff2", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
var ___CSS_LOADER_URL_REPLACEMENT_2___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_2___);
var ___CSS_LOADER_URL_REPLACEMENT_3___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_3___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@font-face {
  font-family: "Samliphopangche";
  src: url(${___CSS_LOADER_URL_REPLACEMENT_0___});
}
@font-face {
  font-family: "NanumBarunGothic";
  font-weight: 300;
  font-style: normal;
  src: url(${___CSS_LOADER_URL_REPLACEMENT_1___}) format("woff2");
  font-display: swap;
}
@font-face {
  font-family: "NanumBarunGothic";
  font-weight: 400;
  font-style: normal;
  src: url(${___CSS_LOADER_URL_REPLACEMENT_2___}) format("woff2");
  font-display: swap;
}
@font-face {
  font-family: "NanumBarunGothic";
  font-weight: 700;
  font-style: normal;
  src: url(${___CSS_LOADER_URL_REPLACEMENT_3___}) format("woff2");
  font-display: swap;
}`, "",{"version":3,"sources":["webpack://./src/scss/font.scss"],"names":[],"mappings":"AAEA;EACI,8BAAA;EACA,4CAAA;AADJ;AAIA;EACI,+BAAA;EACA,gBAAA;EACA,kBAAA;EACA,4DAAA;EACA,kBAAA;AAFJ;AAKA;EACI,+BAAA;EACA,gBAAA;EACA,kBAAA;EACA,4DAAA;EACA,kBAAA;AAHJ;AAMA;EACI,+BAAA;EACA,gBAAA;EACA,kBAAA;EACA,4DAAA;EACA,kBAAA;AAJJ","sourcesContent":["@charset \"utf-8\";\r\n\r\n@font-face {\r\n    font-family: \"Samliphopangche\";\r\n    src: url(\"../../public/assets/fonts/SDSamliphopangcheTTFBasic.ttf\");\r\n}\r\n\r\n@font-face {\r\n    font-family: 'NanumBarunGothic';\r\n    font-weight: 300;\r\n    font-style: normal;\r\n    src: url(\"../../public/assets/fonts/NanumBarunGothicLight.woff2\") format('woff2');\r\n    font-display: swap;\r\n}\r\n\r\n@font-face {\r\n    font-family: 'NanumBarunGothic';\r\n    font-weight: 400;\r\n    font-style: normal;\r\n    src: url(\"../../public/assets/fonts/NanumBarunGothic.woff2\") format('woff2');\r\n    font-display: swap;\r\n}\r\n\r\n@font-face {\r\n    font-family: 'NanumBarunGothic';\r\n    font-weight: 700;\r\n    font-style: normal;\r\n    src: url(\"../../public/assets/fonts/NanumBarunGothicBold.woff2\") format('woff2');\r\n    font-display: swap;\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
