import React, { useContext, useEffect, useRef, useState } from 'react';

import { PageSettings } from '../../../config/page-settings';
import '../../../scss/pages/signup.scss';
import strings from "../../../lang/strings";
import { IS_OFFLINE_MODE, IS_UI_MODE, LOGIN_TYPE } from "../../../config/const";
import { storage } from "../../../mobx/store";
import SelectAreaPopup from "../../popup/select-area";
import TermsPopup from '../../popup/terms-popup';

const SignupMainInfo = ({ onNext }) => {

  const context = useContext(PageSettings);

  const [user, setUser] = useState(storage.getSignupUser());
  const [authCode, setAuthCode] = useState('');
  const [authCodeRequest, setAuthCodeRequest] = useState(false);
  const [phoneAuth, setPhoneAuth] = useState(false);

  const addressType = useRef('');
  const [showPopup, togglePopup] = useState(false);
  const [termsViewType, setTermsViewType] = useState(0);

  const imageRef = useRef(null);
  const [companyFile, setCompanyFile] = useState(null);

  useEffect(() => {
  }, [])

  useEffect(() => {
    if (companyFile != null) {
      uploadImage();
    } else {
      onChange('company_photo', '');
    }
  }, [companyFile])

  const onChange = (prop, value) => {
    if (typeof prop == "object") {
      setUser(prev => {
        return {
          ...prev,
          ...prop
        }
      });
      return;
    }
    setUser(prev => {
      return {
        ...prev,
        [prop]: value
      }
    });
  }

  const uploadImage = () => {
    if (companyFile == null) {
      return;
    }

    let formData = new FormData();
    formData.append('file', companyFile, companyFile.name);

    context.post(
      'upload/image/member',
      formData,
      response => {
        onChange('company_photo', response.image);
      }
    );
  }

  const photoCallback = e => {
    const files = e.target.files;
    if (files.length < 1) {
      return;
    }

    let file = files[0];
    let reader = new FileReader();

    reader.onloadend = () => {
      setCompanyFile(file);
    };
    reader.readAsDataURL(file);
  }

  const onPhoneVerify = () => {
    if ((user?.phone ?? '') == '') {
      context.showAlert('전화번호를 입력하세요.');
      return;
    }

    // 인증번호 발송 api 호출
    context.post(
      'auth/requestAuthCode',
      {
        phone: user?.phone ?? ''
      },
      response => {
        const { auth_code } = response;
        setAuthCodeRequest(true);
        setPhoneAuth(false);
        setAuthCode('');
        context.showAlert('인증번호가 발송되었습니다.\n1분안에 입력하세요.');
      }
    );
  };

  const onConfirmCode = () => {
    if (phoneAuth) {
      return;
    }

    if (authCode == '') {
      context.showAlert('인증번호를 입력하세요.');
      return;
    }

    // 인증번호 체크 api 호출
    context.post(
      'auth/confirmAuthCode',
      {
        phone: user?.phone ?? '',
        auth_code: authCode
      },
      response => {
        setPhoneAuth(true);
        context.showAlert('전화번호 인증이 완료되었습니다.');
      }
    );
  };

  const onSearchAddress = type => {
    addressType.current = type;
    togglePopup(true);
  }

  const onViewTerms = (type) => {
    setTermsViewType(type);
    togglePopup(true);
  }

  const onCheckInviteCode = () => {
    if ((user?.invite_code ?? '') == '') {
      context.showAlert(strings.signup.invitation_code_placeholder);
      return;
    }

    context.post(
      'auth/checkInvite',
      {
        code: user?.invite_code ?? ''
      },
      response => {
        onChange('inviter', response);
      }
    );
  }

  const onConfirm = () => {
    if ((user?.login_type ?? LOGIN_TYPE.EMAIL) == LOGIN_TYPE.EMAIL) {
      if ((user?.email ?? '') == '') {
        context.showAlert(strings.signup.email_placeholder);
        return;
      }
      if ((user?.pwd ?? '') == '') {
        context.showAlert(strings.signup.password_placeholder);
        return;
      }
      if (
        (user?.pwd ?? '').search(/[0-9]/g) < 0 ||
        (user?.pwd ?? '').search(/[a-z]/ig) < 0 ||
        (user?.pwd ?? '').search(/[A-Z]/ig) < 0
      ) {
        context.showAlert(strings.signup.password_placeholder);
        return;
      }
      if ((user?.pwd_confirm ?? '') != (user?.pwd ?? '')) {
        context.showAlert(strings.signup.confirm_placeholder);
        return;
      }
    }
    if ((user?.name ?? '') == '') {
      context.showAlert('이름을 입력하세요.');
      return;
    }
    if ((user?.phone ?? '') == '') {
      context.showAlert('전화번호를 입력하세요.');
      return;
    }
    if (!phoneAuth) {
      context.showAlert('전화번호 인증을 진행하세요.');
      return;
    }
    if ((user?.company_name ?? '') != '' && (user?.company_photo ?? '') == '') {
      context.showAlert('기업회원은 사진을 첨부하세요.');
      return;
    }
    // if ((user?.home_address ?? '') == '') {
    //   context.showAlert(strings.signup.select_home_address);
    //   return;
    // }
    // if ((user?.office_address ?? '') == '') {
    //   context.showAlert(strings.signup.select_office_address);
    //   return;
    // }
    if (!(user?.terms_agree ?? false) || !(user?.privacy_agree ?? false)) {
      context.showAlert('약관을 동의하세요.');
      return;
    }

    // 이메일 중복체크
    context.get(
      'auth/check',
      {
        email: user?.email ?? '',
        phone: user?.phone ?? '',
        name: user?.name ?? '',
        company_name: user?.company_name ?? '',
        company_file: user?.company_photo ?? '',
      },
      response => {
        const { email, phone, name } = response;
        if (!email && user?.login_type == LOGIN_TYPE.EMAIL) {
          context.showAlert(strings.signup.duplicate_email);
          return;
        }
        if (!phone || !name) {
          context.showAlert(strings.signup.duplicate_phone);
          return;
        }

        goNext();
      }
    );
  }

  const goNext = () => {
    storage.saveSignupUser(user);
    onNext?.();
  }

  const getAreaString = id => {
    const areaList = context.getSettingList('area');
    const selectArea = areaList.find(parent => parent.children.some(child => child.id == id));
    if (!selectArea) {
      return '';
    }

    return selectArea.name + ' ' + selectArea.children.find(it => it.id == id)?.name;
  }

  return (
    <div className='main-info'>
      <div>
        {
          (user?.login_type ?? LOGIN_TYPE.EMAIL) == LOGIN_TYPE.EMAIL &&
          <>
            {/*ID*/}
            <label className='caption'>{strings.signup.id}</label>
            <div className='input-wrapper'>
              <input type='email' value={user?.email || ''} placeholder={strings.signup.email_placeholder}
                onChange={e => onChange('email', e.target.value)} />
            </div>
            {/*Password*/}
            <label className='caption'>{strings.signup.password}</label>
            <div className='input-wrapper'>
              <input type='password' value={user?.pwd || ''} placeholder={strings.signup.password_placeholder}
                onChange={e => onChange('pwd', e.target.value)} />
            </div>
            {/*Password Confirm*/}
            <label className='caption'>{strings.signup.password}</label>
            <div className='input-wrapper'>
              <input type='password' value={user?.pwd_confirm || ''} placeholder={strings.signup.confirm_placeholder}
                onChange={e => onChange('pwd_confirm', e.target.value)} />
            </div>
          </>
        }
        <label className='caption'>{'이름'}</label>
        <div className='input-wrapper'>
          <input type='text' value={user?.name || ''} placeholder={'이름을 입력하세요.'}
            onChange={e => onChange('name', e.target.value)} />
        </div>
        <label className='caption'>{'전화번호'}</label>
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <div className='input-wrapper' style={{ flex: 1 }}>
            <input type='text' value={user?.phone || ''} placeholder={'전화번호를 입력하세요.'}
              onChange={e => {
                onChange('phone', e.target.value);

                // 인증번호 발송후 또는 인증완료 후 전화번호를 수정하면 인증을 다시 진행하도록 처리
                setPhoneAuth(false);
                setAuthCodeRequest(false);
              }} />
          </div>
          {/*Phone identity Button*/}
          <button className='btn-phone-verify' onClick={onPhoneVerify}>{'인증하기'}</button>
        </div>
        {
          authCodeRequest &&
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <div className='input-wrapper' style={{ flex: 1 }}>
              <input type='text' value={authCode} placeholder={'인증번호를 입력하세요.'}
                onChange={e => setAuthCode(e.target.value)} />
            </div>
            <button className='btn-phone-verify' onClick={onConfirmCode}>{strings.signup.confirm}</button>
          </div>
        }
        {/*Home Address*/}
        {/* <label className='caption'>{strings.signup.home_address}</label>
        <div className='address-wrapper'>
          <div className='address'>{getAreaString(user?.home_address) || ''}</div>
          <button className='btn-search' onClick={() => onSearchAddress('home')}>
            <img srcSet={'./assets/images/icon_search.png 3x'} />
          </button>
        </div> */}
        {/*Office Address*/}
        {/* <label className='caption'>{strings.signup.office_address}</label>
        <div className='address-wrapper'>
          <div className='address'>{getAreaString(user?.office_address) || ''}</div>
          <button className='btn-search' onClick={() => onSearchAddress('office')}>
            <img srcSet={'./assets/images/icon_search.png 3x'} />
          </button>
        </div>
        <label className='address-guide'>{strings.signup.enter_address_correctly}</label> */}
        <label className='caption'>{strings.my_page.register_corporate}</label>
        <div className='input-wrapper'>
          <input type='text' value={user?.company_name || ''} placeholder={strings.my_page.company_placeholder}
            onChange={e => onChange('company_name', e.target.value)} />
        </div>
        <div className='sub-caption'>- 사원증, 재직증명서 사진을 첨부하세요</div>
        {
          (user?.company_photo ?? '') != '' &&
          <img className='company-image' src={user?.company_photo.getImages(true)} alt='photo' />
        }
        <button className='btn-upload' onClick={() => imageRef.current?.click()}>{strings.my_page.upload}</button>
        <input type='file' ref={imageRef} style={{ display: 'none' }} accept={'image/png,image/jpg,image/jpeg'}
          onChange={photoCallback} />
        {/*마케팅 수신*/}
        <label className='caption'>{strings.signup.consent_marketing}</label>
        <div className='checkbox-group'>
          <label className='checkbox' onClick={() => onChange('market_email', 1 - (user?.market_email ?? 0))}>
            <img srcSet={(user?.market_email ?? 0) == 1 ?
              './assets/images/icon_checkbox_checked.png 3x' :
              './assets/images/icon_checkbox_unchecked.png 3x'} />
            {strings.signup.email}
          </label>
          <label className='checkbox' onClick={() => onChange('market_sms', 1 - (user?.market_sms ?? 0))}>
            <img srcSet={(user?.market_sms ?? 0) == 1 ?
              './assets/images/icon_checkbox_checked.png 3x' :
              './assets/images/icon_checkbox_unchecked.png 3x'} />
            {strings.signup.sms}
          </label>
          <label className='checkbox' onClick={() => onChange('market_push', 1 - (user?.market_push ?? 0))}>
            <img srcSet={(user?.market_push ?? 0) == 1 ?
              './assets/images/icon_checkbox_checked.png 3x' :
              './assets/images/icon_checkbox_unchecked.png 3x'} />
            {strings.signup.push}
          </label>
        </div>
        {/*약관동의*/}
        <label className='caption'>{strings.signup.agree_terms}</label>
        <label className='checkbox'
          onClick={() => {
            if (!(user?.terms_agree ?? false) || !(user?.privacy_agree ?? false)) {
              onChange('terms_agree', true)
              onChange('privacy_agree', true)
            } else {
              onChange('terms_agree', false)
              onChange('privacy_agree', false)
            }
          }}
          style={{ marginTop: 8 }}>
          <img srcSet={((user?.terms_agree ?? false) && (user?.privacy_agree ?? false)) ?
            './assets/images/icon_checkbox_checked.png 3x' :
            './assets/images/icon_checkbox_unchecked.png 3x'} />
          {'전체 동의'}
        </label>
        <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between', marginTop: 8 }}>
          <label className='checkbox'
            onClick={() => onChange('terms_agree', !(user?.terms_agree ?? false))}>
            <img srcSet={(user?.terms_agree ?? false) ?
              './assets/images/icon_checkbox_checked.png 3x' :
              './assets/images/icon_checkbox_unchecked.png 3x'} />
            {strings.signup.terms_of_use}
          </label>
          <button className='btn-invite' onClick={() => onViewTerms(1)}>{'보기'}</button>
        </div>
        <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between', marginTop: 8 }}>
          <label className='checkbox'
            onClick={() => onChange('privacy_agree', !(user?.privacy_agree ?? false))}>
            <img srcSet={(user?.privacy_agree ?? false) ?
              './assets/images/icon_checkbox_checked.png 3x' :
              './assets/images/icon_checkbox_unchecked.png 3x'} />
            {strings.signup.privacy_policy}
          </label>
          <button className='btn-invite' onClick={() => onViewTerms(2)}>{'보기'}</button>
        </div>
        {/*Invitation Code*/}
        <label className='caption' style={{ marginTop: 23 }}>{strings.signup.invitation_code}</label>
        <div className='invite-code'>
          <div className='input-wrapper' style={{ flex: 1, margin: 0 }}>
            <input
              type='text' value={user?.invite_code || ''} placeholder={strings.signup.invitation_code_placeholder}
              onChange={e => {
                onChange({
                  'inviter': '',
                  'invite_code': e.target.value
                });
              }} />
          </div>
          <button className='btn-invite' onClick={onCheckInviteCode}>{strings.signup.confirm}</button>
        </div>
      </div>
      <button className='btn-confirm' onClick={onConfirm}>{strings.signup.confirm}</button>
      {/* <SelectAreaPopup
        area={user?.[`${addressType.current}_address`] ?? 0}
        active={showPopup}
        onClose={(id) => {
          if (id > 0) {
            onChange(`${addressType.current}_address`, id);
          }
          togglePopup(false);
        }} /> */}
      <TermsPopup
        active={showPopup}
        type={termsViewType}
        onClose={() => {
          setTermsViewType(0);
          togglePopup(false);
        }}
      />
    </div>
  );
}

export default SignupMainInfo;
