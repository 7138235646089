import React from 'react';

const GiftAlertPopup = React.memo((props) => {

  const {
    show = false,
    onModalClose
  } = props;

  const onClose = res => {
    onModalClose?.(res || false);
  };

  return (
    <div className={'modal' + (show ? ' active' : '')} onClick={onClose}>
      <div className='content' onClick={e => e.stopPropagation()}>
        <div className='body'>
          <label className='title'>{'티켓을 선물하시겠습니까?'}</label>
          <label className='text'>{'- 확인을 누를경우 자동으로 티켓이 차감됩니다.\n (무조건 선물을 보내셔야 합니다.)\n- 확인을 누르면, 선물을 보내기 위해 카카오톡 페이지로 이동합니다.'.nl2br()}</label>
          <label className='text' style={{color: 'red', marginTop: 10}}>{'\n(*선물 한 당일에는 사용할수 없으니, 이점 유의해 주세요.)'.nl2br()}</label>
        </div>
        <div className='footer'>
          <button onClick={() => onClose(false)}>{'취소'}</button>
          <button onClick={() => onClose(true)}>{'확인'}</button>
        </div>
      </div>
    </div>
  );
});

export default GiftAlertPopup;
