// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.splash {
  display: flex;
  width: 100%;
  height: 100vh;
  justify-content: center;
  align-items: center;
  background: #ff3a4a;
}
.splash .logo-img {
  width: 100%;
  padding: 0 50px;
}
.splash .logo-img img {
  width: 100%;
  object-fit: cover;
}`, "",{"version":3,"sources":["webpack://./src/scss/pages/splash.scss","webpack://./src/scss/variables/coreVars.scss"],"names":[],"mappings":"AAEA;EACE,aAAA;EACA,WAAA;EACA,aAAA;EACA,uBAAA;EACA,mBAAA;EACA,mBCHQ;ADEV;AAGE;EACE,WAAA;EACA,eAAA;AADJ;AAGI;EACE,WAAA;EAEA,iBAAA;AAFN","sourcesContent":["@import \"../variables/coreVars.scss\";\r\n\r\n.splash {\r\n  display: flex;\r\n  width: 100%;\r\n  height: 100vh;\r\n  justify-content: center;\r\n  align-items: center;\r\n  background: $primary;\r\n\r\n  .logo-img {\r\n    width: 100%;\r\n    padding: 0 50px;\r\n    \r\n    img {\r\n      width: 100%;\r\n      // height: 100%;\r\n      object-fit: cover;\r\n    }\r\n  }\r\n}\r\n","// Storing Core Variables for styling\r\n\r\n// Color\r\n$black: #181818;\r\n$white: #ffffff;\r\n$primary: #ff3a4a;\r\n\r\n// Dimension\r\n$topBarHeight: 54px;\r\n$topBarColor: $black;\r\n$topBarFontSize: 17px;\r\n$topBarFontWeight: bold;\r\n$gnbHeight: 70px;\r\n$windowWidthForMedia: 400px;\r\n$windowHeightForMedia: 100vh;\r\n\r\n// Animation\r\n$animationTime: .2s;\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
