import React, { useContext, useEffect, useState } from 'react';

import { PageSettings } from '../../../config/page-settings';
import strings from "../../../lang/strings";
import { storage } from "../../../mobx/store";

const SignupCategory = () => {

  const context = useContext(PageSettings);
  const [user, setUser] = useState(storage.getSignupUser());

  useEffect(() => {
    storage.saveSignupUser(user);
  }, [user]);

  const onChange = (prop, value) => {
    if (typeof prop == "object") {
      setUser(prev => {
        return {
          ...prev,
          ...prop
        }
      });
      return;
    }
    setUser(prev => {
      return {
        ...prev,
        [prop]: value
      }
    });
  }

  const onSelect = id => {
    const categoryList = user?.category ?? [];
    onChange(
      'category',
      categoryList.includes(id) ? categoryList.filter(it => it != id) : categoryList.concat(id)
    );
  }

  return (
    <div className='gender-age'>
      <h3 className='caption'>{strings.signup.select_category.nl2br()}</h3>
      <label className='guide'>{strings.signup.multiple_possible}</label>
      <div className='category-wrapper'>
        {
          context.getSettingList('category').map((it, idx) => (
            <button key={idx} className={'btn-category' + ((user?.category ?? []).includes(it.id) ? ' active' : '')}
                    onClick={() => onSelect(it.id)}>
              {it.name}
            </button>
          ))
        }
      </div>
    </div>
  );
}

export default SignupCategory;
