import React, { useRef } from 'react';

const ListView = React.memo(({ children, className, onLoadMore }) => {
  const ref = useRef(null);
  const onScroll = () => {
    const reachEnd = ref.current.scrollTop + ref.current.clientHeight >= ref.current.scrollHeight;
    if (reachEnd) {
      onLoadMore?.();
    }
  };

  return (
    <div className={className} ref={ref} onScroll={onScroll}>
      {children}
    </div>
  );
});

export default ListView;
