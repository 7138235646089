// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.find {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100vh;
}
.find .content {
  flex: 1 1;
  padding: 76px 15px 0;
}
.find .content .caption {
  font-size: 13px;
  color: #303030;
  font-weight: bold;
}
.find .content .input-wrapper {
  height: 28px;
  border: 1px solid #c6c6c6;
  padding: 0 15px;
  margin-top: 8px;
  margin-bottom: 17px;
}
.find .content .input-wrapper input, .find .content .input-wrapper textarea {
  width: 100%;
  height: 100%;
  font-size: 12px;
  color: #181818;
}
.find .content .input-wrapper textarea {
  padding: 10px 0 0;
  resize: none;
}
.find .btn-confirm {
  width: 100%;
  height: 51px;
  background-color: #ff3a4a;
  color: #ffffff;
  font-size: 16px;
}`, "",{"version":3,"sources":["webpack://./src/scss/pages/find.scss","webpack://./src/scss/variables/coreVars.scss"],"names":[],"mappings":"AAEA;EACE,aAAA;EACA,sBAAA;EACA,WAAA;EACA,aAAA;AADF;AAGE;EACE,SAAA;EACA,oBAAA;AADJ;AAGI;EACE,eAAA;EACA,cAAA;EACA,iBAAA;AADN;AAII;EACE,YAAA;EACA,yBAAA;EACA,eAAA;EACA,eAAA;EACA,mBAAA;AAFN;AAIM;EACE,WAAA;EACA,YAAA;EACA,eAAA;EACA,cC1BA;ADwBR;AAKM;EACE,iBAAA;EACA,YAAA;AAHR;AAQE;EACE,WAAA;EACA,YAAA;EACA,yBCrCM;EDsCN,cCvCI;EDwCJ,eAAA;AANJ","sourcesContent":["@import \"../variables/coreVars.scss\";\r\n\r\n.find {\r\n  display: flex;\r\n  flex-direction: column;\r\n  width: 100%;\r\n  height: 100vh;\r\n\r\n  .content {\r\n    flex: 1;\r\n    padding: $topBarHeight+22px 15px 0;\r\n\r\n    .caption {\r\n      font-size: 13px;\r\n      color: #303030;\r\n      font-weight: bold;\r\n    }\r\n\r\n    .input-wrapper {\r\n      height: 28px;\r\n      border: 1px solid #c6c6c6;\r\n      padding: 0 15px;\r\n      margin-top: 8px;\r\n      margin-bottom: 17px;\r\n\r\n      input, textarea {\r\n        width: 100%;\r\n        height: 100%;\r\n        font-size: 12px;\r\n        color: $black;\r\n      }\r\n      \r\n      textarea {\r\n        padding: 10px 0 0;\r\n        resize: none;\r\n      }\r\n    }\r\n  }\r\n\r\n  .btn-confirm {\r\n    width: 100%;\r\n    height: 51px;\r\n    background-color: $primary/*#171717*/;\r\n    color: $white;\r\n    font-size: 16px;\r\n  }\r\n}\r\n","// Storing Core Variables for styling\r\n\r\n// Color\r\n$black: #181818;\r\n$white: #ffffff;\r\n$primary: #ff3a4a;\r\n\r\n// Dimension\r\n$topBarHeight: 54px;\r\n$topBarColor: $black;\r\n$topBarFontSize: 17px;\r\n$topBarFontWeight: bold;\r\n$gnbHeight: 70px;\r\n$windowWidthForMedia: 400px;\r\n$windowHeightForMedia: 100vh;\r\n\r\n// Animation\r\n$animationTime: .2s;\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
