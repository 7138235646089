import React, { useContext, useRef } from 'react';

import { PageSettings } from '../../../config/page-settings';
import Container from "../../../components/control/container";
import '../../../scss/pages/mypage.scss';
import strings from "../../../lang/strings";
import { withRouter } from "react-router-dom";
import { inject, observer } from "mobx-react";
import { storage } from "../../../mobx/store";

const MyPage = ({ history, rootStore }) => {

  const context = useContext(PageSettings);
  const me = rootStore.getMe;

  const imageRef = useRef(null);

  const onChangeAccount = () => {
    if (!rootStore.isSignedIn) {
      context.showConfirm(
        strings.my_page.go_to_login,
        '',
        strings.signup.confirm,
        strings.my_page.cancel,
        response => {
          if (response == true) {
            history.push('/login');
          }
        }
      );
      return;
    }

    history.push('/account-info');
  }

  const onProfileChange = () => {
    if (!rootStore.isSignedIn) {
      context.showConfirm(
        strings.my_page.go_to_login,
        '',
        strings.signup.confirm,
        strings.my_page.cancel,
        response => {
          if (response == true) {
            history.push('/login');
          }
        }
      );
      return;
    }

    imageRef.current?.click();
  }

  const onMemberShip = () => {
    // 멤버십등록화면으로 이동
    history.push('/membership/level');
  }

  const onMenu = idx => {
    switch (idx) {
      case 0:
        if (rootStore.isSignedIn) {
          history.push('/ticket-log');
        } else {
          context.showConfirm(
            strings.my_page.go_to_login,
            '',
            strings.signup.confirm,
            strings.my_page.cancel,
            response => {
              if (response == true) {
                history.push('/login');
              }
            }
          )
        }
        break;
      case 1:
        history.push('/faq');
        break;
      case 2:
        storage.removeNoticeEnv();
        history.push('/notice');
        break;
      case 3:
        // if (rootStore.isSignedIn) {
          history.push('/inquiry');
        // } else {
        //   context.showConfirm(
        //     strings.my_page.go_to_login,
        //     '',
        //     strings.signup.confirm,
        //     strings.my_page.cancel,
        //     response => {
        //       if (response == true) {
        //         history.push('/login');
        //       }
        //     }
        //   )
        // }
        break;
      default:
    }
  }

  const onWithdraw = () => {
    // 앱에서는 탈퇴하지 못하고 웹페이지에서만 탈퇴가능하게 한다.
    const filter = "win16|win32|win64|mac|macintel";
    if (navigator.platform) {
      if (0 > filter.indexOf(navigator.platform.toLowerCase())) {
        context.showAlert(strings.my_page.web_withdraw);
      } else {
        history.push('/withdraw');
      }
    }
  }

  const onLogout = () => {
    if (rootStore.isSignedIn) {
      context.showConfirm(
        strings.side_menu.log_out_confirm,
        '',
        strings.signup.confirm,
        strings.my_page.cancel,
        response => {
          if (response == true) {
            storage.setTab(0);
            storage.removeAutoLogin();
            rootStore.signOut();
            history.push('/intro');
          }
        }
      )
    } else {
      history.push('/intro');
    }
  };

  const photoCallback = e => {
    const files = e.target.files;
    if (files.length < 1) {
      return;
    }

    const file = files[0];
    let formData = new FormData();
    formData.append('file', file, file.name);

    context.post(
      'upload/image/member',
      formData,
      response => {
        saveInfo(response.image);
      }
    );
  }

  const saveInfo = (profile = '') => {
    context.post(
      'member/update',
      {
        ...me,
        profile
      },
      () => {
        context.getMyInfo();
        // context.showAlert(strings.my_page.account_modify);
      }
    );
  }

  return (
    <Container className='mypage'>
      <div className='top'>
        <div className='profile'>
          {
            !rootStore.isSignedIn ?
              <img srcSet={'./assets/images/img_default_profile.png 3x'} alt='profile' />
              :
              <img src={me?.profile?.getFullUrl()} alt='profile' />
          }
          <label onClick={onProfileChange}>{strings.my_page.profile_settings}</label>
          <input type='file' ref={imageRef} style={{ display: 'none' }} accept={'image/png,image/jpg,image/jpeg'}
                 onChange={photoCallback} />
        </div>
        <button className='btn-change-account' onClick={onChangeAccount}>{strings.my_page.change_account}</button>
      </div>
      {
        (rootStore.isSignedIn && me?.level == null) &&
        <button className='btn-login-require' onClick={onMemberShip}>
          {strings.my_page.membership_register_required}
        </button>
      }
      {
        (rootStore.isSignedIn && me?.level != null && me?.ticket.next_date != '') &&
        <div style={{marginTop: 30, textAlign: 'center'}}>
          다음 티켓 생성일 : {me?.ticket.next_date}
        </div>
      }
      <div className='side-content'>
        <button className='menu-item' onClick={() => onMenu(0)}>
          <div className='icon' />
          <label>{strings.side_menu.ticket_usage}</label>
          <img srcSet={'./assets/images/icon_arrow_right_line.png 3x'} alt='arrow' />
        </button>
        <button className='menu-item' onClick={() => onMenu(1)}>
          <div className='icon' />
          <label>{strings.side_menu.faq}</label>
          <img srcSet={'./assets/images/icon_arrow_right_line.png 3x'} alt='arrow' />
        </button>
        <button className='menu-item' onClick={() => onMenu(2)}>
          <div className='icon' />
          <label>{strings.side_menu.notice_event}</label>
          <img srcSet={'./assets/images/icon_arrow_right_line.png 3x'} alt='arrow' />
        </button>
        <button className='menu-item' onClick={() => onMenu(3)}>
          <div className='icon' />
          <label>{strings.side_menu.partnership_inquiry}</label>
          <img srcSet={'./assets/images/icon_arrow_right_line.png 3x'} alt='arrow' />
        </button>
      </div>
      <div className='btn-group'>
        <button className='btn-withdraw' onClick={onLogout}>
          {rootStore.isSignedIn ? strings.side_menu.log_out : strings.side_menu.log_in}
        </button>
        {
          rootStore.isSignedIn &&
          <button className='btn-withdraw' onClick={onWithdraw}>{strings.my_page.withdraw}</button>
        }
      </div>
      <div className='company-info'>
        <div style={{fontSize: 16, fontWeight: 'bold'}}>티켓쉐어</div>
        <div style={{marginTop: 5}}>주소: 경기도 성남시 분당구 대왕판교로 645번길 12</div>
        <div style={{marginTop: 5}}>대표이사: 박승현</div>
        <div style={{marginTop: 5}}>사업자번호: 590-18-02321</div>
        <div style={{marginTop: 5}}>통신판매업신고번호: 2024-성남분당A-0703</div>
        <div style={{marginTop: 5}}>전화번호: 0502-5551-2397</div>
        <div style={{marginTop: 5}}>이메일: co@ticketshare.co.kr</div>
      </div>

    </Container>
  );
}

export default withRouter(inject('rootStore')(observer(MyPage)));
