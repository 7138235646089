import React from 'react';
import AreaList from "../../components/control/area-list";


const SelectAreaPopup = ({ active, area, onClose }) => {

  return (
    <div className={'select-area' + (active ? ' active' : '')} onClick={() => onClose(0)}>
      <div className='content' onClick={e => e.stopPropagation()}>
        <AreaList
          selectData={[area]}
          onChange={id => onClose(id)}
        />
      </div>
    </div>
  );

};

export default SelectAreaPopup;
