import { action, observable } from 'mobx';
import decode from "jwt-decode";

export const storage = {
  getSignupUser: () => {
    const info = sessionStorage.getItem('signup_user');
    return info ? JSON.parse(info) : {};
  },
  saveSignupUser: user => {
    sessionStorage.setItem('signup_user', JSON.stringify(user));
  },
  removeSignupUser: () => {
    sessionStorage.removeItem('signup_user');
  },
  setTab: tab => {
    sessionStorage.setItem('active_tab', tab);
  },
  getTab: () => {
    return sessionStorage.getItem('active_tab') ?? 0;
  },
  removeNoticeEnv: () => {
    sessionStorage.removeItem('notice_env');
  },
  saveNoticeEnv: (column, keyword, activePage) => {
    sessionStorage.setItem('notice_env', JSON.stringify({
      column, keyword, activePage
    }));
  },
  getNoticeEnv: () => {
    const env = sessionStorage.getItem('notice_env');
    if (env == null) {
      return {
        column: 'title',
        keyword: '',
        activePage: 1
      };
    } else {
      return JSON.parse(env);
    }
  },
  setAutoLogin: (type, id, pwd = '') => {
    localStorage.setItem('auto_login', JSON.stringify({
      type, id, pwd
    }));
  },
  getAutoLogin: () => {
    const info = localStorage.getItem('auto_login');
    if (info == null) {
      return null;
    } else {
      return JSON.parse(info);
    }
  },
  removeAutoLogin: () => {
    localStorage.removeItem('auto_login');
  },
  setSelectedPosition: (address, lat, lng) => {
    localStorage.setItem('selected_position', JSON.stringify({
      address, lat, lng
    }));
  },
  getSelectedPosition: () => {
    const info = localStorage.getItem('selected_position');
    if (info == null) {
      return null;
    } else {
      return JSON.parse(info);
    }
  },
};

export const rootStore = observable({

  token: null,
  me: null,
  setting: null,
  address: null,
  latitude: null,
  longitude: null,

  signIn: token => {
    rootStore.token = token;
    sessionStorage.setItem('token', token);
  },

  setMyInfo: user => {
    rootStore.me = user;
    sessionStorage.setItem('user', JSON.stringify(user));
  },

  signOut: () => {
    rootStore.token = null;
    rootStore.me = null;
    sessionStorage.removeItem('token');
    sessionStorage.removeItem('user');
  },

  saveSetting: setting => {
    rootStore.setting = setting;
    sessionStorage.setItem('setting', JSON.stringify(setting));
  },

  setAddress: address => {
    rootStore.address = address;
    sessionStorage.setItem('address', address);
  },

  setLatitude: latitude => {
    rootStore.latitude = latitude;
    sessionStorage.setItem('latitude', latitude);
  },
  
  setLongitude: longitude => {
    rootStore.longitude = longitude;
    sessionStorage.setItem('longitude', longitude);
  },

  get getSetting() {
    if (rootStore.setting == null) {
      try {
        rootStore.setting = JSON.parse(sessionStorage.getItem('setting'));
      } catch (e) {
        console.log(e);
      }
    }
    return rootStore.setting;
  },

  get getMe() {
    if (rootStore.me == null) {
      try {
        rootStore.me = JSON.parse(sessionStorage.getItem('user'));
      } catch (e) {
        console.log(e);
      }
    }
    return rootStore.me;
  },

  get getToken() {
    if (rootStore.token == null) {
      rootStore.token = sessionStorage.getItem('token');
    }
    return rootStore.token;
  },

  get isSignedIn() {
    const token = this.getToken;
    try {
      const decoded = decode(token);
      return !!token && decoded.exp > Date.now() / 1000;
    } catch (e) {
      return false;
    }
  },

  get getAddress() {
    if (rootStore.address == null) {
      rootStore.address = sessionStorage.getItem('address');
    }
    return rootStore.address;
  },
  
  get getLatitude() {
    if (rootStore.latitude == null) {
      rootStore.latitude = sessionStorage.getItem('latitude');
    }
    return rootStore.latitude;
  },
  
  get getLongitude() {
    if (rootStore.longitude == null) {
      rootStore.longitude = sessionStorage.getItem('longitude');
    }
    return rootStore.longitude;
  },

}, {
  signIn: action,
  signOut: action,
  setMyInfo: action,
  saveSetting: action,
  setAddress: action,
  setLatitude: action,
  setLongitude: action
});
