import React from 'react';
import { withRouter } from "react-router-dom";
import axios from "axios";
import { rootStore } from "./mobx/store";
import { inject, observer } from "mobx-react";

import { PageSettings } from './config/page-settings.js';
import Content from './components/content/content.jsx';
import LoadingBar from "./components/control/loading-bar";
import Modal from "./components/control/modal";
import { API_URL, HEADER, RESULT_CODE } from "./config/const";
import strings from "./lang/strings";


class App extends React.Component {
  constructor(props) {
    super(props);

    this.toggleMobileSidebar = () => {
      this.setState(state => ({
        pageSidebarToggled: !state.pageSidebarToggled
      }));
    }
    this.handleSetPageSidebar = (value) => {
      this.setState({
        pageSidebar: value
      });
    }
    this.handleSetPageHeader = (value) => {
      this.setState({
        pageHeader: value
      });
    };
    this.handleSetPageBoxedLayout = (value) => {
      if (value === true) {
        document.body.classList.add('boxed-layout');
      } else {
        document.body.classList.remove('boxed-layout');
      }
    };
    this.handleSetDeviceId = (value) => {
      this.setState({
        deviceId: value
      });
    };
    this.toggleLoadingBar = isLoading => {
      this.setState({ pageLoading: isLoading });
    }
    this.showGallery = (image_list, index = 0) => {
      this.setState({
        galleryShow: true,
        galleryData: image_list,
        galleryIndex: index,
      });
    }
    this.showModal = (title, text, okBtn, cancelBtn, cb) => {
      this.setState({
        modal: {
          show: true,
          title: title,
          text: text,
          subText: '',
          okBtn: okBtn,
          cancelBtn: cancelBtn,
          cb: cb
        }
      });
    }
    this.showAlert = (text, cb, okBtn = strings.login.confirm) => {
      this.showModal('', text, okBtn, '', cb);
    }
    this.showConfirm = (title, text, okBtn, cancelBtn, cb) => {
      this.showModal(title, text, okBtn, cancelBtn, cb);
    }
    this.getSettingList = (...name) => {
      let item = this.props.rootStore.getSetting;
      for (props of name) {
        item = item[props];
      }
      return item;
    }
    this.getSettingName = (code, ...name) => {
      let item = this.props.rootStore.getSetting;

      if (name == 'auth') {
        return item[name]?.find(it => it.code == code)?.name ?? '';
      }

      for (props of name) {
        item = item[props];
      }
      return Array.isArray(item) ? (item?.find(it => it.code == code)?.name ?? '') : item[code];
    }
    this.api = axios.create({
      baseURL: API_URL + '/app/',
      headers: {
        [HEADER.LANG]: 'ko'
      }
    })
    this.post = async (uri, params, cbSuccess, cbFail, showProgress = true) => {
      await this.request('POST', uri, params, cbSuccess, cbFail, showProgress);
    }
    this.get = async (uri, params, cbSuccess, cbFail, showProgress = true) => {
      await this.request('GET', uri, params, cbSuccess, cbFail, showProgress);
    }
    this.delete = async (uri, params, cbSuccess, cbFail, showProgress = true) => {
      await this.request('DELETE', uri, params, cbSuccess, cbFail, showProgress);
    }
    this.request = async (method, uri, params, cbSuccess, cbFail, showProgress = true) => {
      if (showProgress) {
        this.toggleLoadingBar(true);
      }

      this.api.defaults.headers.common[HEADER.AUTH_TOKEN] = this.props.rootStore.getToken;

      try {
        let { data } = method !== 'GET' ?
          await this.api[method.toLowerCase()](uri, params)
          :
          await this.api.get(uri, { params });

        if (showProgress) {
          this.toggleLoadingBar(false);
        }

        if (Number(data.result_code) === RESULT_CODE.SUCCESS) {
          cbSuccess?.(data.result_data);
        } else {
          if (cbFail) {
            cbFail?.(data);
          } else {
            this.handleApiError(data);
          }
        }
      } catch (err) {
        console.log(err);

        if (showProgress) {
          this.toggleLoadingBar(false);
        }

        this.showAlert(err.message);
      }
    }

    this.download = async (method, uri, params, cb, showProgress = true) => {
      if (showProgress) {
        this.toggleLoadingBar(true);
      }

      this.api.defaults.headers.common[HEADER.AUTH_TOKEN] = this.props.rootStore.token;
      this.api.defaults.responseType = 'blob';


      try {
        let response = method !== 'GET' ?
          await this.api[method.toLowerCase()](uri, params)
          :
          await this.api.get(uri, { params });

        if (showProgress) {
          this.toggleLoadingBar(false);
        }


        const filename = decodeURIComponent(response.headers["x-suggested-filename"]);

        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', filename); //or any other extension
        document.body.appendChild(link);
        link.click();

      } catch (err) {
        console.log(err);

        if (showProgress) {
          this.toggleLoadingBar(false);
        }

        this.showAlert(err.message);
      }
    }

    this.handleApiError = e => {
      if (Number(e.result_code) === RESULT_CODE.EMPTY_TOKEN ||
        Number(e.result_code) === RESULT_CODE.TOKEN_EXPIRED) {
        this.props.history.replace('/login');
        return;
      }

      this.showAlert(e.result_msg);
    }

    this.state = {
      hasScroll: false,

      pageHeader: true,
      handleSetPageHeader: this.handleSetPageHeader,

      pageSidebar: true,
      pageSidebarToggled: false,
      handleSetPageSidebar: this.handleSetPageSidebar,
      toggleMobileSidebar: this.toggleMobileSidebar,

      handleSetPageBoxedLayout: this.handleSetPageBoxedLayout,

      deviceId: '',
      handleSetDeviceId: this.handleSetDeviceId,

      pageLoading: false,
      toggleLoadingBar: this.toggleLoadingBar,

      osType: '',

      modal: {
        show: false,
        title: '',
        text: '',
        subText: '',
        okBtn: '',
        cancelBtn: '',
        cb: null
      },
      showModal: this.showModal,
      showAlert: this.showAlert,
      showConfirm: this.showConfirm,

      showGallery: this.showGallery,
      galleryShow: false,
      galleryData: [],
      galleryIndex: 0,

      post: this.post,
      get: this.get,
      delete: this.delete,
      download: this.download,

      getMyInfo: this.getMyInfo,
      getSettingInfo: this.getSettingInfo,
      getSettingName: this.getSettingName,
      getSettingList: this.getSettingList,
      getDeviceId: this.getDeviceId,
      getOSType: this.getOSType,
    };
  }

  componentDidMount() {
    const os = window.navigator.appVersion.indexOf('iPhone') !== -1 ? 'ios' : 'android';
    if (window.ReactNativeWebView) {
      this.setState({
        osType: os
      })
    } else {
      this.setState({
        osType: 'web'
      })
    }

    window.addEventListener('scroll', this.handleScroll);
    if (os == 'ios') {
      window.addEventListener('message', this.handleMessage);
    } else {
      document.addEventListener('message', this.handleMessage);
    }

    this.getSettingInfo().then();
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.handleScroll)
  }

  handleMessage = (nativeEvent) => {
    if (typeof nativeEvent?.data != "object" && nativeEvent?.data.isJSON()) {
      const data = JSON.parse(nativeEvent?.data);
      if (data.type == 'goBack') {
        const pathname = window.location.pathname;
        if (pathname == '/intro') {
          let obj = {
            func: 'exitApp',
            params: {}
          };

          let message = JSON.stringify(obj);
          window.ReactNativeWebView.postMessage(message);
        } else if (pathname == '/main') {

        } else {
          this.props.history.goBack();
        }
      }
    }
  }

  getSettingInfo = async () => {
    await this.get(
      'auth/getAppData',
      {},
      response => {
        this.props.rootStore.saveSetting(response);
      },
      null,
      false
    );
  }

  getMyInfo = async () => {
    if (!rootStore.isSignedIn) {
      return;
    }

    await this.get(
      'member/detail/0',
      {},
      response => {
        this.props.rootStore.setMyInfo(response);
      },
      null,
      false
    );
  }

  getDeviceId = () => {
    return this.state.deviceId;
  }

  getOSType = () => {
    return this.state.osType;
  }

  handleScroll = () => {
    this.setState({
      hasScroll: window.scrollY > 0
    });
    let elm = document.getElementsByClassName('nvtooltip');
    for (let i = 0; i < elm.length; i++) {
      elm[i].classList.add('d-none');
    }
  }

  onModalClose = res => {
    this.setState(state => ({
      modal: { ...state.modal, show: false }
    }), () => this.state.modal.cb?.(res));
  }

  render() {
    return (
      <PageSettings.Provider value={this.state}>
        <>
          <Content />
          <Modal {...this.state.modal} onModalClose={this.onModalClose} />
          {this.state.pageLoading && <LoadingBar />}
        </>
      </PageSettings.Provider>
    )
  }
}

export default withRouter(inject('rootStore')(observer(App)));
