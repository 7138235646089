import React, { useEffect, useState } from 'react';

const Pagination = ({ pageCount, activePage, className = '', onPage }) => {

  const BTN_COUNT = 5;    // 한번에 현시하는 버튼갯수

  const [prevButtonShow, togglePrevButton] = useState(true);
  const [nextButtonShow, toggleNextButton] = useState(true);
  const [btnList, setBtnList] = useState([]);

  useEffect(() => {
    let startPageNum = (Math.floor(activePage / BTN_COUNT) - (activePage % BTN_COUNT == 0 ? 1 : 0)) * BTN_COUNT + 1;
    const endPageNum = Math.min(pageCount, startPageNum + BTN_COUNT - 1);

    /*togglePrevButton(activePage > 1);
    toggleNextButton(activePage < pageCount);*/

    const list = [];
    do {
      list.push(startPageNum++);
    } while (startPageNum <= endPageNum)
    setBtnList(list);
  }, [activePage, pageCount]);

  const onPrev = () => {
    if (activePage == 1) {
      return;
    }
    onPage(activePage - 1);
  }

  const onNext = () => {
    if (activePage >= pageCount) {
      return;
    }
    onPage(activePage + 1);
  }

  return (
    <div className={'pagination ' + className}>
      {prevButtonShow && <button className='btn-page' onClick={onPrev}>&lt;</button>}
      {
        btnList.map((it, idx) => (
          <button key={idx} className={'btn-page' + (it == activePage ? ' active' : '')} onClick={() => onPage(it)}>
            {it}
          </button>
        ))
      }
      {nextButtonShow && <button className='btn-page' onClick={onNext}>&gt;</button>}
    </div>
  );
};

export default Pagination;
